import { BASE_URL } from "./config";
import axios from "axios";

export async function get(url, token) {
  const pageRes = await axios(BASE_URL + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return await pageRes.data;
}
export async function post(url, body, token) {

  let headers = {
    "Content-Type": "application/json",

  }

  if(token){
    headers.Authorization = "Bearer " + token
  }


  const pageRes = await fetch(BASE_URL + url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: headers
  });

  try {
    return await pageRes.clone().json();
  } catch (err) {
    // console.log(pageRes.data);

    return await pageRes.text();
  }
}

export async function put(url, body, token) {
  const pageRes = await fetch(BASE_URL + url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  try {
    return await pageRes.clone().json();
  } catch (err) {
    return await pageRes.text();
  }
}

export async function DELETE(url, token) {
  const pageRes = await fetch(BASE_URL + decodeURIComponent(url), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  try {
    return await pageRes.clone().json();
  } catch (err) {
    return await pageRes.text();
  }
}

// http://tqi.herokuapp.com/realestate?realEstateId=

import React from "react";
import Geocode from "react-geocode";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input,
  Typography
} from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EditEstateService } from "../../Services/Estates/estateServices";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { useHistory } from "react-router-dom";
import PlaceSelect from "../../components/Global/PlaceSelect/PlaceSelect";
import { DeleteEstateService } from "../../Services/Estates/estateServices";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "550px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },

  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  },
  padding: {
    padding: "20px"
  }
});

const CreateFolderDialog = ({
  open,
  onClose,
  path,
  callbackEdit,
  estateData,
  company
}) => {
  Geocode.setApiKey("AIzaSyDL94a2o87vZYVP7-h8q9pmcT1IxuGQTFc");
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const classes = useStyles();
  const history = useHistory();
  const [estateName, setEstateName] = useState(estateData.name || "");
  const [estateId, setEstateId] = useState(estateData.realEstateId || "");
  const [loading, setLoading] = useState(false);
  const [estateIdError, setEstateIdError] = useState(false);
  const [estateNameError, setEstateNameError] = useState(false);
  const [estateAdressError, setEstateAdressError] = useState(false);
  const { companyID } = useParams();
  const companyId = Number(companyID);
  const [address, setAdress] = useState(estateData.address);
  const [ writerObj, setWriter] = useState(estateData.writer);
  const [technical, setTechnical] = useState(estateData.technical);
  const [other, setOther] = useState(estateData.other);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [deleteEstateDialog, setDeleteEstateDialog] = useState(false);
  const [deleteEstateName, setDeleteEstateName] = useState("");
  const [copyEstateDialog, setCopyEstateDialog] = useState(false);
  const [copyEstateName, setCopyEstateName] = useState("");
  const isAdmin = useSelector(state => state.admin.admin);
  
  const handleCopyDialogOpen = () => {
    setCopyEstateDialog(true);
  };

  const handleCopyDialogClose = () => {
    setCopyEstateDialog(false);
  };

  const copyEstateNameHandler = name => {
    setCopyEstateName(name);
  };

  const copyEstateConfirmation = () => {
    if (copyEstateName === estateName) {
    } else {
      setCopyEstateDialog(false);
    }
    history.push(`/${companyId}/estates/${copyEstateName}`);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteEstateDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteEstateDialog(false);
  };

  const deleteEstateNameHandler = name => {
    setDeleteEstateName(name);
  };

  const deleteEstateConfirmation = () => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          if (deleteEstateName === estateName) {
            DeleteEstateService(estateData.id, token).then(resp => {
              history.push(`/${companyID}/estates/`);
            });

            setDeleteEstateDialog(false);
          }
        }
      }
    })();
  };

  useEffect(() => {
    setLoading(false);
  }, [open]);

  useEffect(() => {
    if (estateId === "") {
      setEstateIdError(true);
    }
    if (estateName === "") {
      setEstateNameError(true);
    }
    if (address === "") {
      setEstateAdressError(true);
    }
  }, [estateId, estateName, address]);

  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  const createFolder = async () => {
    if (estateData === "" || estateName === "" || estateId === "") {
      return;
    }
    let company = estateData;
    company.name = estateName;
    company.realEstateId = estateId;
    company.latitude = coordinates.lat;
    company.longitude = coordinates.lng;
    company.address = address
    company.writer = writerObj
    company.technical = technical
    company.other=other
    setLoading(true);
    let token = await getTokenSilently();
    const resp = await EditEstateService(companyId, company, token);
    callbackEdit(resp);
    setLoading(false);
    onClose();
  };

  const estateNameHandler = value => {
    if (/[\/]+$/.test(value)) {
      return;
    } else {
      setEstateName(value);
      setEstateNameError(false);
    }
  };
  const estateIdHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      return;
    } else {
      setEstateId(value);
      setEstateIdError(false);
    }
  };

  const adressStateHandler = value => {
    if (/[!@#$%^&*()_+=\[\]{};':"\\|.<>\/?]+$/.test(value)) {
      return;
    } else {
      setAdress(value);
      setEstateAdressError(false);
    }
  };

  async function adressHandler(value) {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    setAdress(value);
    setCoordinates(latLng);
  }

  useEffect(() => {
  
        setAdress(address);
        setEstateAdressError(false);
        setCoordinates({ lat: estateData.latitude, lng: estateData.longitude });
    
    
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Redigera Fastighet</DialogTitle>

      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%", zIndex: "999" }}
        >
          <FormControl>
            <InputLabel>Namn</InputLabel>
            <Input
              value={estateName}
              error={estateNameError}
              onChange={e => {
                estateNameHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field1}</InputLabel>
            <Input
              value={estateId}
              error={estateIdError}
              onChange={e => {
                estateIdHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field2}</InputLabel>
            <Input
              value={writerObj}
              onChange={e => {
                setWriter(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field3}</InputLabel>
            <Input
              value={technical}
              onChange={e => {
                setTechnical(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field4}</InputLabel>
            <Input
              value={other}
              onChange={e => {
                setOther(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <PlaceSelect
              address={address}
              adressHandler={adressHandler}
              adressStateHandler={adressStateHandler}
              addressError={estateAdressError}
            ></PlaceSelect>
          </FormControl>
        </FormControl>
        <>
          {/* <Button onClick={handleCopyDialogOpen}>Kopiera fastighet</Button> */}
          <Button onClick={handleDeleteDialogOpen} color="secondary">
            TA BORT
          </Button>
        </>
      </DialogContent>
      <Dialog open={copyEstateDialog} onClose={handleCopyDialogClose}>
        <InputLabel>Skriv in nytt namn på fastighet</InputLabel>
        <Input
          value={copyEstateName}
          onChange={e => {
            copyEstateNameHandler(e.target.value);
          }}
        />
        <Button onClick={copyEstateConfirmation}>Spara</Button>
      </Dialog>
      <Dialog
        open={deleteEstateDialog}
        onClose={handleDeleteDialogClose}
        PaperProps={{ classes: { root: classes.padding } }}
      >
        <Typography>Vill du verkligen ta bort denna fastigheten?</Typography>
        <InputLabel>Skriv in namnet på valda fastigheten</InputLabel>
        <Input
          value={deleteEstateName}
          onChange={e => {
            deleteEstateNameHandler(e.target.value);
          }}
        />
        <Button onClick={deleteEstateConfirmation} color="secondary">
          TA BORT
        </Button>
      </Dialog>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          onClick={createFolder}
          color="primary"
          variant="contained"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFolderDialog;

import { useState, useEffect } from "react";
import { GetFolderService } from "../Folders/folderServices";
import { OpenFileService } from "../Folders/folderServices";
import { useAuth0 } from "../../react-auth0-spa";

export const useGetFolder = (path, upLoadedFile, companyId, navigate) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const [fileData, setFileData] = useState({
    files: [],
    folders: [],
  });
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState();
  
  useEffect(() => {
    setFileData({
      files: [],
      folders: [],
    })
  }, [upLoadedFile])
  useEffect(() => {
    setFileLoading(true);
    let go = true;
    const fetchAllPaged = async () => {
      const token = await getTokenSilently();
      if(token) {
        let i = 0;
        while (go === true) {
          const resp = await GetFolderService(path, companyId, token, i);

          const mappedFiles = resp.files.map((file) => ({
            ...file,
            path: path
          }))
          const mappedFolders = resp.folders.map((folder) => ({
            ...folder,
            path: path
          }))
          
          const shouldSetFolders = i === 0
          setFileData((state) => ({
            files: uniqueByKey([...(state.files || []), ...mappedFiles]),
            folders: uniqueByFolder([...(state.folders || []), ...(shouldSetFolders ? mappedFolders : [])]),
          }));
          setFileLoading(false);
          if(resp.files.length === 0) {
            break;
          }
          i++;
        }
      }
    }
    fetchAllPaged(path);
    return () => { 
      go = false;
    } 
  }, [path, upLoadedFile, companyId]);

  return [fileData, fileLoading];
};

export const useOpenFile = (companyId, file) => {
  const [fileData, setFileData] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState();

  useEffect(() => {
    setFileLoading(true);
    async function asyncAPIrequest() {
      const resp = await OpenFileService(companyId, file);
      setFileData(resp);
      setFileLoading(false);
    }
    asyncAPIrequest();
  }, [file, companyId]);

  return [fileData, fileLoading];
};


const uniqueByKey = (array) => {
  const map = new Map();

  for(let i = 0; i < array.length; i++) {
    const object = array[i];
    if(!map.get(object.key)) {
      map.set(object.key, object)
    }
  }

  return Array.from(map.values())

}

const uniqueByFolder = (array) => {
  const map = new Map();

  for(let i = 0; i < array.length; i++) {
    const object = array[i];
    if(!map.get(object.folder)) {
      map.set(object.folder, object)
    }
  }

  return Array.from(map.values())

}
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetFolder } from "../../Services/Folders/folderHooks";
import { useGetEstate } from "../../Services/Estates/estateHooks";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogActions,
  LinearProgress,
  Slide
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EstateCard from "../EstateListPage/EstateCard";
import Breadcrumbs from "../../components/Navigation/Breadcrumbs/Breadcrumbs";
import FolderIcon from "@material-ui/icons/Folder";
import { useCallback } from "react";
import { useState } from "react";
import { OpenFileService } from "../../Services/Folders/folderServices";
import EditUserDialog from "./EditUserDialog";
import { useAuth0 } from "../../react-auth0-spa";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    miNHeight: "350px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  }
});

const UserPage = ({ users }) => {
  const classes = useStyles();
  let { id } = useParams();
  let { folderPath } = useParams();

  const { companyID } = useParams();
  const companyId = Number(companyID);
  const [uploadedFile, setUploadedFile] = useState("");
  const [files, fileLoading] = useGetFolder(
    folderPath,
    uploadedFile,
    companyId
  );

  const currentEstate = useSelector(state => state.estates.estates);
  const [editedEstate, setEditedEstate] = useState("");
  const [estateData, loading, error] = useGetEstate(
    id,
    companyId,
    editedEstate
  );
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [editEstate, setEditEstate] = useState(false);
  const [open, setOpen] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [chosenImg, setChosenImg] = useState("");
  const [currentFileIndex, setCurrentFileIndex] = useState();
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("default");
  const [chosenPdf, setChosenPdf] = useState();

  const [thisUser, setThisUser] = useState(null);

  useEffect(() => {
    if (users) {
      const loadedUser = users.filter(user => Number(user.id) === Number(id));
      setThisUser(...loadedUser);
    }
  }, [users]);

  if (!estateData) {
    return <CircularProgress />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleProgress = e => {
    setProgressBarValue(e);
    setUploaded(true);
  };
  // const pdfHandler = file => {
  //   console.log(file);
  //   return <ExamplePDFViewer file={file} />;
  // };

  //   const fileCrumbs = relativePaths.map((item, index) => {
  //     const itemPath = relativePaths.reduce((acc, v, i) => {
  //       if (i > index) {
  //         return acc;
  //       }
  //       let tmpAcc = acc;
  //       if (i > 0) {
  //         tmpAcc += "/";
  //       }
  //       tmpAcc += v;
  //       return tmpAcc;
  //     });

  //     return {
  //       text: item,
  //       to: `/estates/${estateData.id}/${itemPath}`
  //     };
  //   });


  return (
    <section>
      <EditUserDialog
        userData={thisUser}
        open={editEstate}
        onClose={() => {
          setEditEstate(false);
        }}
        // path={folderPath}
        // callbackEdit={callbackEdit}
      />

      <header style={{ marginBottom: 30 }}>
        <Breadcrumbs
          crumbs={[
            {
              text: "Användare",
              to: "/users"
            },
            {
              text: estateData.name,
              to: `/users/${estateData.id}`
            }
          ]}
        />
        <Grid justify={"space-between"} alignItems={"center"} container>
          <Grid item>
            {thisUser ? (
              <>
                <Typography
                  style={{ marginBottom: 20, marginTop: 20 }}
                  variant="h4"
                  component="h1"
                >
                  {thisUser.name}
                </Typography>
                <Typography
                  style={{ marginBottom: 20, marginTop: 20 }}
                  variant="h5"
                  component="h5"
                >
                  {thisUser.level}
                </Typography>
              </>
            ) : null}
          </Grid>
          <div>
            <Grid container spacing={1}></Grid>
            <Grid container justify="center" spacing={1}>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setEditEstate(true);
                  }}
                >
                  Redigera
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </header>

      {/* <Grid container spacing={3}>
        <Grid item md={9}>
          <Grid alignItems={"center"} container spacing={1}>
            <FolderIcon />
            <Grid item>
              <Breadcrumbs hideBase crumbs={fileCrumbs} />
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 22, marginBottom: 8 }} />

          {loading ? <CircularProgress /> : null}
        </Grid>
        <Grid item md={3}>
          <EstateCard hideActions estate={estateData} />
        </Grid>
      </Grid> */}
    </section>
  );
};

export default UserPage;

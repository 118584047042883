import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useGetUsers } from "../../Services/Users/userHooks";
import UsersListHeader from "./UsersListHeader";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const UsersListPage = ({ users }) => {
  const classes = useStyles();

  const [allUsers] = useGetUsers();

  return (
    <Container>
      <UsersListHeader />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              {/* <TableCell align="right">Id</TableCell> */}
              <TableCell align="right">E-post</TableCell>
              <TableCell align="right">Datum&nbsp;(skapad)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allUsers
              ? allUsers.map(user => (
                  <TableRow key={user.user_id}>
                    <TableCell component="th" scope="row">
                      <Link
                        style={{
                          activeStyle: "none",
                          textDecoration: "none"
                        }}
                        to={`/users/${user.nickname}`}
                      >
                        {user.nickname}
                      </Link>
                    </TableCell>
                    {/* <TableCell align="right">{user.user_id}</TableCell> */}
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">
                      {" "}
                      {user.last_login}
                      {/* {formatDate(company.created)} */}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default UsersListPage;

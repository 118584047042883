import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Breadcrumbs from "../../components/Navigation/Breadcrumbs";
import CreateUserDialog from "./CreateUserDIalog";

export const UsersListHeader = ({ selC, callbackCreate }) => {
  const [open, setOpen] = useState(false);

  


  return (
    <header>
      <Breadcrumbs
        crumbs={[
          {
            text: "Users",
            to: "/users"
          }
        ]}
      />
      <Grid justify={"space-between"} alignItems={"center"} container>
        <Grid item>
          <Typography
            style={{ marginBottom: 20, marginTop: 20 }}
            variant="h4"
            component="h1"
          >
            Users
          </Typography>
        </Grid>
      </Grid>
    </header>
  );
};

export default UsersListHeader;

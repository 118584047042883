import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useGetFolder } from "../../Services/Folders/folderHooks";
import { useGetEstate } from "../../Services/Estates/estateHooks";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogActions,
  LinearProgress,
  Slide
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EstateCard from "../EstateListPage/EstateCard";
import Breadcrumbs from "../../components/Navigation/Breadcrumbs/Breadcrumbs";
import FolderIcon from "@material-ui/icons/Folder";
import { useCallback } from "react";
import { useState } from "react";
import CreateFolderDialog from "./CreateFolderDialog";
import EditEstateDialog from "./EditEstateDialog";
import EstateFileTable from "./EstateFileTable";
import MyDropZone from "../../components/MyDropZone/MyDropZone";
import FileModal from "./FileModal";
import { OpenFileService, DownloadAll } from "../../Services/Folders/folderServices";
import { useAuth0 } from "../../react-auth0-spa";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { setCompany } from "../../Redux/actions/companyActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    miNHeight: "350px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  }
});

const EstatePage = ({ pdfHandler }) => {
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const history = useHistory();
  const classes = useStyles();
  let { id } = useParams();
  let { folderPath, companyID } = useParams();
  // let p = folderPath.split("/").map((x,i) => i === 0 ? x : decodeURIComponent(x)).join("/")
  let p = folderPath
  // console.log("my p is ", p)
  const companies = useSelector(state => state.companies.companies);
  const companyId = Number(companyID);
  const [uploadedFile, setUploadedFile] = useState("");
  let [files, fileLoading] = useGetFolder(
    folderPath,
    uploadedFile,
    companyId
  );

  files = {
    files: files.files.filter((f) => f.path === folderPath),
    folders: files.folders.filter((f) => f.path === folderPath)
  }
  
  const companiesFiltered = companies.filter(company => company.id === Number(companyId))[0]
  const role = companiesFiltered ? companiesFiltered.users.filter((userObj) => userObj.userId === user.sub)[0].userRole : null
  const isAdmin =  role === "ADMIN" || role === "SUPER_ADMIN"

  const currentEstate = useSelector(state => state.estates.estates);
  const [editedEstate, setEditedEstate] = useState("");
  const [estateData, loading, error] = useGetEstate(
    id,
    companyId,
    editedEstate
  );
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [editEstate, setEditEstate] = useState(false);
  const [open, setOpen] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [chosenImg, setChosenImg] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState();
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("default");
  const [chosenPdf, setChosenPdf] = useState();
  const [openFile, setOpenFile] = useState("");
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFilename, setUploadFileName] = useState("");
  const [downloadAllLoading, setDownloadAllLoading] = useState(false)

  const [checkedFileIds, setCheckedFileIds] = useState([])
  const dispatch = useDispatch();
  console.log("checkedfiles", checkedFileIds)
  useEffect(() => {
    // Clear checked files when path changes
    setCheckedFileIds([])
  }, [folderPath])

  useEffect(() => {
    dispatch(setCompany(companyId));
  }, []);

  const uploadHandler = value => {
    setUploadInProgress(value);
  };
  const setPdf = pdfFile => {
    setChosenPdf(pdfFile);
  };

  const fileViewHandler = (img, index, type, name) => {
    setFileName(name);
    setCurrentFileIndex(index);
    setFileType(type);
    setFilePreview(!filePreview);
    setChosenImg(img);
    setOpenFile(img);
  };

  const fileViewPrevHandler = index2 => {
    (async () => {
      let newIndex = index2 - 1;
      const thisFile = files.files.filter((file, index) => index === newIndex);
      if (thisFile.length === 0) {
        return;
      }

      const type = thisFile[0].key.match(/\.[0-9a-z]+$/i);
      setOpenFile(thisFile[0].key);
      setFileType(type[0]);
      setCurrentFileIndex(newIndex);
      setFileName(
        thisFile[0].key
          .replace(/\/$/, "")
          .split("/")
          .pop()
      );
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          OpenFileService(companyId, thisFile[0].key, token).then(resp => {
            setFileType(type[0]);
            setCurrentFileIndex(newIndex);
            setChosenImg(resp);
            setFileName(
              thisFile[0].key
                .replace(/\/$/, "")
                .split("/")
                .pop()
            );
          });
        }
      }
    })();
  };

  const fileVieNextHandler = index2 => {
    (async () => {
      const newIndex = index2 + 1;
      const thisFile = files.files.filter((file, index) => index === newIndex);
      if (thisFile.length === 0) {
        return;
      }
      const type = thisFile[0].key.match(/\.[0-9a-z]+$/i);
      setOpenFile(thisFile[0].key);
      setFileType(type[0]);
      setCurrentFileIndex(newIndex);
      setFileName(
        thisFile[0].key
          .replace(/\/$/, "")
          .split("/")
          .pop()
      );
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          OpenFileService(companyId, thisFile[0].key, token).then(resp => {
            setFileType(type[0]);
            setCurrentFileIndex(newIndex);
            setChosenImg(resp);
            setFileName(
              thisFile[0].key
                .replace(/\/$/, "")
                .split("/")
                .pop()
            );
          });
        }
      }
    })();
  };

  const callbackUl = useCallback(char => {
    setUploadedFile(char);
  }, []);

  const callbackEdit = useCallback(value => {
    setEditedEstate(value);
  }, []);

  const relativePaths = folderPath.replace(/\/$/, "").split("/");

  if (!estateData) {
    return <CircularProgress />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleProgress = e => {
    setProgressBarValue(e);
  };

  const uploadFileNameHandler = e => {
    setUploadFileName(e);
  };

  const goBackHandler = () => {
    setFilePreview(false);
    setPdf(null);
    setChosenPdf(null);
    // history.goBack();
  };

  const estateBlank = estate => {
  };

  const downloadAll = async () => {
    setDownloadAllLoading(true)
    const token = await getTokenSilently();
    const response = await DownloadAll(decodeURIComponent(folderPath), checkedFileIds, companyId, token)
    setDownloadAllLoading(false)
    var a = document.createElement("a"); //Create <a>
    a.href = "data:application/zip;base64," + response; //Image Base64 Goes here
    a.download = "archive.zip"; //File name Here
    a.click(); //Downloaded file 
  }

  const fileCrumbs = relativePaths.map((item, index) => {
    // item = item.replaceAll(" ", "%20")
    // console.log(item)
    // item = decodeURIComponent(item)
    const itemPath = relativePaths.reduce((acc, v, i) => {
      if (i > index) {
        return acc;
      }
      let tmpAcc = acc;
      if (i > 0) {
        tmpAcc += "/";
      }
      tmpAcc += v;
      return tmpAcc;
    });

    if(index === 0){
      return {
        text: estateData.name,
        to: `/estates/${estateData.id}/${itemPath}`
      };  
    }
  
    return {
      text: item,
      to: `/estates/${estateData.id}/${itemPath}`
    };


  });

  const LICENSE_KEY =
    process.env.REACT_APP_PSPDFKIT_LICENSE_KEY &&
    process.env.REACT_APP_PSPDFKIT_LICENSE_KEY.trim();

  if (!LICENSE_KEY || LICENSE_KEY === "YOUR_LICENSE_KEY_HERE") {
    throw new Error(`No or invalid PSPDFKit license key found.
  Please open package.json and assign it to REACT_APP_PSPDFKIT_LICENSE_KEY.

  To request a trial license, please go to:

    https://pspdfkit.com/try/.

  After requesting a trial license, you can find your license key by opening the
  link in the email and going to:

    https://pspdfkit.com/guides/web/current/standalone/integration/#toc_example-application`);
  } else if (LICENSE_KEY.length < 120) {
    throw new Error(`The supplied PSPDFKit license key is too short.

  This usually happens when using the NPM_KEY instead of the LICENSE_KEY.

  After requesting a trial license, you can find your license key by opening the
  link in the email and going to:

    https://pspdfkit.com/guides/web/current/standalone/integration/#toc_example-application`);
  }
  

  return (
    <section>
      {chosenPdf ? history.push("/" + companyId + "/estatespdf/"+ folderPath + "/" + chosenPdf.name) : (
        <>
          {filePreview ? (
            <FileModal
              filePreview={filePreview}
              chosenImg={chosenImg}
              fileType={fileType}
              fileViewHandler={fileViewHandler}
              currentFileIndex={currentFileIndex}
              fileVieNextHandler={fileVieNextHandler}
              fileViewPrevHandler={fileViewPrevHandler}
              setPdf={setPdf}
              pdfHandler={pdfHandler}
              fileName={fileName}
              imgLoading={imgLoading}
              openFile={openFile}
              companyId={companyId}
              path={folderPath}
            />
          ) : null}
          
          <CreateFolderDialog
            open={createFolderOpen}
            onClose={() => {
              setCreateFolderOpen(false);
            }}
            path={p}
            callbackUl={callbackUl}
          />
          <EditEstateDialog
            estateData={estateData}
            open={editEstate}
            onClose={() => {
              setEditEstate(false);
            }}
            path={folderPath}
            callbackEdit={callbackEdit}
            company={companiesFiltered}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            PaperProps={{ classes: { root: classes.root } }}
          >
            <MyDropZone
              handleClose={handleClose}
              folderPath={folderPath}
              callbackUl={callbackUl}
              handleProgress={handleProgress}
              progressBarValue={progressBarValue}
              uploadHandler={uploadHandler}
              uploadFileNameHandler={uploadFileNameHandler}
            ></MyDropZone>
            <DialogActions></DialogActions>
          </Dialog>
          <header style={{ marginBottom: 30 }}>
            <Breadcrumbs
              crumbs={[
                {
                  text: companiesFiltered.name,
                  to: "/estates"
                }
              ]}
            />
            <Grid justify={"space-between"} alignItems={"center"} container>
              <Grid item>
                <Typography
                  style={{ marginBottom: 20, marginTop: 20 }}
                  variant="h4"
                  component="h1"
                >
                  {estateData.name}
                </Typography>
              </Grid>
              <div>
                <Grid container spacing={1}>
                <Grid item>
                        {
                          isAdmin ? (
                            <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setCreateFolderOpen(true);
                            }}
                          >
                            Skapa mapp
                          </Button>
  
                          ) : null
                        }
                  </Grid>
                <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleClickOpen}
                        >
                          Ladda upp
                        </Button>
                      </Grid>
               

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={downloadAllLoading}
                      onClick={() => {
                        downloadAll();

                      }}
                    >
                      Ladda ner { downloadAllLoading && (<CircularProgress style={{ marginLeft: 8 }} size={16} />) }
                    </Button>
                  </Grid>
                  {
                    isAdmin && (
                      <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setEditEstate(true);
                            }}
                          >
                            Redigera
                          </Button>
                      </Grid>
                    )
                  }
                </Grid>
              </div>
            </Grid>
          </header>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <Grid alignItems={"center"} container spacing={1}>
                <FolderIcon />
                <Grid item>
                  <Breadcrumbs hideBase crumbs={fileCrumbs} />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 22, marginBottom: 8 }} />


              {loading ? <CircularProgress /> : null}
              {!loading && (
                  <>
                    {progressBarValue !== 0 ? (
                      progressBarValue === 100 ? null : (
                        <LinearProgress
                          variant="determinate"
                          value={progressBarValue}
                          color="primary"
                        ></LinearProgress>
                      )
                    ) : null}
                    {
                      files && (
                        <EstateFileTable
                          files={files}
                          fileLoading={fileLoading}
                          fileViewHandler={fileViewHandler}
                          callbackUl={callbackUl}
                          checkedFileIds={checkedFileIds}
                          setCheckedFileIds={setCheckedFileIds}
                        />
                      )
                    }
                  </>
                )}
              {!files && <section></section>}
            </Grid>
            <Grid item xs={12} md={3}>
              {/* {progressBarValue === 0 || progressBarValue === 100 ? ( */}
              <EstateCard
                hideActions
                estate={estateData}
                uploadInProgress={uploadInProgress}
                progressBarValue={progressBarValue}
                selectEstate={estateBlank}
                company={companiesFiltered}
              />
              {/* ) : null} */}
            </Grid>
          </Grid>
        </>
      )}
    </section>
  );
};

export default EstatePage;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EditCompanyService, GetAllCompaniesService } from "../../../Services/Companies/companiesServices";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../../../react-auth0-spa";
import { setCompanies } from '../../../Redux/actions/companyActions'

const useStyles = makeStyles({
  root: {
    width: "25rem",
    //minHeight: "550px",
    //height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const EditCompanyDialog = ({
  companyData,
  editCompany,
  onClose,
  callbackEdit
}) => {
  const classes = useStyles();
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();

  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState(companyData.name || "");
  const [companyOrg, setCompanyOrg] = useState(companyData.orgNumber || "");
  const [zip, setZip] = useState(companyData.zip || "");
  const [site, setSite] = useState(companyData.site || "");


  const [field1, setField1] = useState(companyData.field1 || "");
  const [field2, setField2] = useState(companyData.field2 || "");
  const [field3, setField3] = useState(companyData.field3 || "");
  const [field4, setField4] = useState(companyData.field4 || "");

  const [companyOrgField, setCompanyOrgField] = useState(companyData.orgField || "");

  const [loading, setLoading] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyOrgError, setCompanyOrgError] = useState(false);

  
  const { companyID } = useParams();
  const companyId = Number(companyID);

  useEffect(() => {
    if (companyName === "") {
      setCompanyNameError(true);
    }
    if (companyOrg === "") {
      setCompanyOrgError(true);
    }
  }, [companyName, companyOrg]);

  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  const redactComapany = async () => {
    setLoading(true);
    let company = companyData;
    company.name = companyName;
    company.orgNumber = companyOrg;
    company.updated = new Date();
    company.zip = zip;
    company.site = site
    company.field1 = field1;
    company.field2 = field2;
    company.field3 = field3;
    company.field4 = field4;
    company.orgField = companyOrgField;

    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          const resp = await EditCompanyService(companyId, company, token);
          const companies = await GetAllCompaniesService(token);
          console.log("companies", companies)
          dispatch(setCompanies(companies))
          callbackEdit(resp);
          setLoading(false);
          onClose();
        }
      }
    })();
  };

  const companyNameHandler = value => {
    if (/[\\|\/]+$/.test(value)) {
      return;
    } else {
      setCompanyName(value);
      setCompanyNameError(false);
    }
  };

  const companyOrgHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      return;
    } else {
      setCompanyOrg(value);
      setCompanyOrgError(false);
    }
  };

  return (
    <Dialog
      open={editCompany}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Redigera organisation</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%" }}
        >
          <FormControl>
            <InputLabel>Namn</InputLabel>
            <Input
              value={companyName}
              error={companyNameError}
              onChange={e => {
                companyNameHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Adress</InputLabel>
            <Input
              value={companyOrg}
              onChange={e => {
                companyOrgHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Postnummer</InputLabel>
            <Input
              value={zip}
              onChange={e => {
                setZip(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Hemsida</InputLabel>
            <Input
              value={site}
              onChange={e => {
                setSite(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Field 1</InputLabel>
            <Input
              value={field1}
              onChange={e => {
                setField1(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Field 2</InputLabel>
            <Input
              value={field2}
              onChange={e => {
                setField2(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Field 3</InputLabel>
            <Input
              value={field3}
              onChange={e => {
                setField3(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Field 4</InputLabel>
            <Input
              value={field4}
              onChange={e => {
                setField4(e.target.value);
              }}
            />
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          onClick={redactComapany}
          color="primary"
          variant="contained"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCompanyDialog;

import { combineReducers } from "redux";
import companiesReducer from "./companiesReducer";
import estatesReducer from "./estatesReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";

export default combineReducers({
  companies: companiesReducer,
  estates: estatesReducer,
  user: userReducer,
  admin: adminReducer
});

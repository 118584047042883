import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import HouseIcon from "@material-ui/icons/House";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory, useLocation } from "react-router-dom";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { useAuth0 } from "../../../react-auth0-spa";
import { Container, Hidden } from "@material-ui/core";
import CompanyMenu from "../CompanyMenu/CompanyMenu";
import { useSelector, useDispatch } from "react-redux";
import GroupIcon from "@material-ui/icons/Group";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { useParams } from "react-router-dom";
import config from "../../../auth_config.json";
import { setCompany } from "../../../Redux/actions/companyActions";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    profileButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
     // padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      marginTop: 72,
      marginBottom: 0,
      flexGrow: 1,
      //padding: theme.spacing(3)
    }
  })
);

const Header = ({ children }) => {

  
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [estateView, setEstateView] = useState("map");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const { companyID } = useParams();
  const dispatch = useDispatch();

  const companiesFromRedux = useSelector(state => state.companies.companies);
  const isAdmin = true

  const selectedCompany = companiesFromRedux.find(
    c => c.id === Number(companyID)
  );

  const sC = useSelector(state => state.companies.company);
  
  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    console.log("dispactch?");
    dispatch(setCompany(sC));
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleView = value => {
    setEstateView(value);
    history.push(`/${sC}`);
  };

  const toggleViewUsers = () => {
    history.push("/users");
  };

  const toggleViewSupport = () => {
    history.push(`/support/${sC}`);
  };

  const toggleCompanies = () => {
    history.push("/companies");
  };

  const toggleViewAdmin = () => {
    history.push(`/admin/${sC}`);
  };


  const drawer = (
    <>
    <div className={classes.toolbar}>
    <CompanyMenu />

    <IconButton onClick={handleDrawerClose}>
      {theme.direction === "rtl" ? (
        <ChevronRightIcon />
      ) : (
        <ChevronLeftIcon />
      )}
    </IconButton>
  </div>
  <Divider />
  <List
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%"
    }}
  >
    <ListItem
      onClick={() => {
        toggleView();
      }}
      button
      key={"Fastigheter"}
    >
      <ListItemIcon>{<ApartmentIcon />}</ListItemIcon>
      <ListItemText primary={"Fastigheter"} />
    </ListItem>
    {!isAdmin || !desktop ? null : (
      <ListItem
        onClick={() => {
          toggleViewAdmin();
        }}
        button
        key={"Admin"}
      >
        <ListItemIcon>{<SupervisorAccountIcon />}</ListItemIcon>
        <ListItemText primary={"Admin"} />
      </ListItem>
      // <>
      //   {" "}
      //   <ListItem
      //     onClick={() => {
      //       toggleViewUsers();
      //     }}
      //     button
      //     key={"Users"}
      //   >
      //     <ListItemIcon>{<GroupIcon />}</ListItemIcon>
      //     <ListItemText primary={"Användare"} />
      //   </ListItem>
      //   <ListItem
      //     onClick={() => {
      //       toggleCompanies();
      //     }}
      //     button
      //     key={"Companies"}
      //   >
      //     <ListItemIcon>{<HouseIcon />}</ListItemIcon>
      //     <ListItemText primary={"Företag"} />
      //   </ListItem>
      // </>
    )}
    {/* <ListItem style={{ flex: 10, flexGrow: 1 }}></ListItem> */}
    <ListItem
      onClick={() => {
        toggleViewSupport();
      }}
      button
      key={"Support"}
      style={{ marginTop: "auto" }}
    >
      <ListItemIcon>{<ContactSupportIcon />}</ListItemIcon>
      <ListItemText primary={"Support"} />
    </ListItem>
  </List>
  </>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
        [classes.appBarShift]: open && desktop
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.profileButton, {
              [classes.hide]: open && desktop
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {`${selectedCompany ? selectedCompany.name : ""}`}
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={menuOpen}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push("/");
                }}
              >
                Byt organisation
              </MenuItem>
              <MenuItem
                onClick={() => {
                  // logout();
                  logout({
                    returnTo: "https://dokumentationsportal.tqi.se/"
                  });
                  handleClose();
                }}
              >
                Logga ut
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden xsDown implementation="js">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
         {drawer}
        </Drawer>
      </Hidden>
      <Hidden smUp implementation="js">
        <Drawer
          variant="temporary"
          open={open}
          >
            {drawer}
          </Drawer>
      </Hidden>
      <Container>
        <main className={classes.content}>{children}</main>
      </Container>
    </div>
  );
};

export default Header;

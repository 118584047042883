import { get, post, put, DELETE } from "../restTemplate";

const base = "realestate?sort=name&companyId=";
const del = "realestate?realEstateId=";
const createfolder = "s3/createfolder?companyId=";
const markerBase = "realestate/getall?companyId=";

export const GetEstatesService = (page, companyId, token, size = 20) => {
  return get(`/${base}${companyId}&page=${page}&size=${size}`, token);
};

export const GetEstatesMarkers = (companyId, token) => {
  return get(`/${markerBase}${companyId}`, token);
};

export const GetEstateService = (id, token) => {
  return get(`/${base}${id}`, token);
};

export const PostEstateService = (body, selC, token) => {
  return post(`/${base}${selC}`, body, token);
};

export const CreateEstateFolderService = (
  folderPath,
  inputData,
  companyId,
  token
) => {
  let obj = {
    path: decodeURIComponent(folderPath) + '/',
    folderName:decodeURIComponent(inputData)
  }

  return post(
    `/${createfolder}${companyId}`,
    obj,
    token
  );
};

export const DeleteEstateService = (id, token) => {
  return DELETE(`/${del}${id}`, token);
};

export const EditEstateService = (companyId, body, token) => {
  
  return put(`/${base}${companyId}`, body, token);
};

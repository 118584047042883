import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Breadcrumbs from "../../components/Navigation/Breadcrumbs/Breadcrumbs";
import CreateCompaniesUser from "./CreateCompaniesUser";

export const CompaniesPageHeader = ({ selC, callbackUl }) => {
  const [open, setOpen] = useState(false);
  return (
    <header>
      <Breadcrumbs
        crumbs={[
          {
            text: "Organisationer",
            to: "/companies"
          }
        ]}
      />
      <Grid justify={"space-between"} alignItems={"center"} container>
        <Grid item>
          <Typography
            style={{ marginBottom: 20, marginTop: 20 }}
            variant="h4"
            component="h1"
          >
            Organisation
          </Typography>
        </Grid>
        <div>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Skapa organisation
              </Button>
              <CreateCompaniesUser
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                selC={selC}
                callbackUl={callbackUl}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </header>
  );
};

import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Breadcrumbs from "../../../components/Navigation/Breadcrumbs/Breadcrumbs";
import CreateCompaniesUser from "./CreateCompaniesUser";
import { useAuth0 } from "../../../react-auth0-spa";

export const CompaniesPageHeader = ({ selC, callbackUl }) => {
  const [open, setOpen] = useState(false);
  const {
    getIdTokenClaims,
    user,
  } = useAuth0();
  
  return (
    <header>
      {/* <Breadcrumbs
        crumbs={[
          {
            text: "Företag",
            to: "/companies"
          }
        ]}
      /> */}
      <Grid justify={"space-between"} alignItems={"center"} container>
        <Grid item>
          <Typography
            style={{ marginBottom: 20, marginTop: 20 }}
            variant="h4"
            component="h1"
          >
            Organisationer
          </Typography>
        </Grid>
        <div>
          <Grid container spacing={1}>
              {
                
                user["https://tqi.se/roles"] && user["https://tqi.se/roles"].includes("admin") ? (

                
                  <Grid item>

                  <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Skapa organisation
                </Button>
                <CreateCompaniesUser
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  selC={selC}
                  callbackUl={callbackUl}
                />
              </Grid>
            ) : null
                
              }
          </Grid>
        </div>
      </Grid>
    </header>
  );
};

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";
import { useState } from "react";
import { CreateEstateFolderService } from "../../Services/Estates/estateServices";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "15rem",
    height: "15rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },

  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const CreateFolderDialog = ({ open, onClose, path, callbackUl }) => {
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const classes = useStyles();
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { companyID } = useParams();
  const companyId = Number(companyID);

  useEffect(() => {
    setFolderName("");
    setLoading(false);
  }, [open]);
  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  const createFolder = async () => {
    if (folderName.trim() === "") {
      setFolderNameError(true);
      return;
    }
    setLoading(true);
    if (isAuthenticated) {
      const token = await getTokenSilently();
      let fName = folderName.trim().replace(/_/g, " ")
      if (token) {
        const resp = await CreateEstateFolderService(
          path.split("/").map((x) => encodeURIComponent(decodeURIComponent(x))).join("/"),
          encodeURIComponent(fName),
          companyId,
          token
        );
        callbackUl(resp);
        setLoading(false);
        onClose();
      }
    }
  };

  const checkCharacters = name => {
    if (/[\\|\/<>_%#]+$/.test(name)) {
      return;
    } else {
      setFolderName(name);
      setFolderNameError(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Skapa mapp</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <FormControl fullwidth="true" disabled={loading}>
          <FormControl>
            <InputLabel>Namn</InputLabel>
            <Input
              value={folderName}
              error={folderNameError}
              onChange={e => {
                checkCharacters(e.target.value);
              }}
            />
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          onClick={createFolder}
          color="primary"
          variant="contained"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFolderDialog;

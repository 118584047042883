import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input,
  TextField,
  MenuItem,
  Select
} from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PostUserService } from "../../../Services/Users/userServices";
import { useAuth0 } from "../../../react-auth0-spa";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "550px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },

  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const userrole = [
  {
    value: "Användare"
  },
  {
    value: "Adminstratör"
  },
  {
    value: "Superadmin"
  }
];

const AddUserCompanyDialog = ({ addUser, open, onClose, id, callbackAdd }) => {
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [roleError, setRoleError] = useState(false);

  const roleHandler = e => {
    setRole(e.target.value);
    setRoleError(false);
  };

  const emailHandler = value => {
    setEmail(value);
    setEmailError(false);
  };

  useEffect(() => {
    setLoading(false);
  }, [open]);
  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  const createUserHandler = () => {
    if (email === "") {
      setEmailError(true);
      return;
    }
    if (role === "") {
      setRoleError(true);
      return;
    }

    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          const resp = await PostUserService(
            {
              email: email,
              companyId: id,
              role: role
            },
            token
          );
          setEmail("");
          setRole("");
          callbackAdd(resp);
        }
      }
    })();
    onClose();
  };

  return (
    <Dialog
      open={addUser}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Skapa User</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%" }}
        >
          <FormControl>
            <InputLabel>E-post</InputLabel>
            <Input
              value={email}
              onChange={e => {
                emailHandler(e.target.value);
              }}
              error={emailError}
            />
          </FormControl>

          <FormControl>
            <Select
              value={role}
              onChange={roleHandler}
              displayEmpty
              className={classes.selectEmpty}
              error={roleError}
            >
              <MenuItem value="" disabled>
                Roll
              </MenuItem>
              <MenuItem value={"USER"}>User</MenuItem>
              <MenuItem value={"ADMIN"}>Admin</MenuItem>
            </Select>
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={() => createUserHandler()}
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserCompanyDialog;

import { get, post, put, DELETE } from "../restTemplate";

const base = "company";

export const GetAllCompaniesService = token => {
  return get(`/${base}`, token);
};
export const GetCompaniesService = (page, companyId, token) => {
  return get(`/${base}?companyId=${companyId}&page=${page}`, token);
};

export const GetCompanyService = id => {
  return get(`/${base}/${id}/`);
};

export const PostCompanyService = (body, token) => {
  return post(`/${base}/create`, body, token);
};

export const EditCompanyService = (companyId, body, token) => {
  return put(`/${base}?companyId=${companyId}`, body, token);
};

export const DeleteCompanyService = (companyId, token) => {
  return DELETE(`/${base}?companyId=${companyId}`, token)
}
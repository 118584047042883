import React, { Component } from "react";
import { UploadFileService } from "../../Services/Folders/folderServices";
import axios from "axios";
import { Button } from "@material-ui/core";
import {ReadPDFService, GetPartsOfFile} from './../../Services/Folders/folderServices'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";

export default class PSPDFKit extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { scroll: false, zoomTo:null, token:"",tag:"", downloadParts: false, loading:false, showError:false };
    this._instance = null;
    this._container = null;

    this.download.bind(this)
    this.onRef = this.onRef.bind(this);
    this.load = this.load.bind(this);
    this.unload = this.unload.bind(this);
    this.downloadParts = this.downloadParts.bind(this);

  }

  onRef(container) {
    this._container = container;
  }

  async load(props) {
    this.props.handleProgress(0);


    try {
      let red = await ReadPDFService(props.folderPath , props.companyId, await props.getTokenSilently())
      this.setState({token:red.token, tag:red.tag})
      this._instance = await window.PSPDFKit.load({
        container: this._container,
        licenseKey: props.licenseKey,
        baseUrl: "https://tqi.qte.nu/",
        documentId: red.tag,
        authPayload: { jwt: red.token },
        instant: false,
        isAPStreamRendered: annotation => {
          // Render rectangle annotations using their AP streams.
          if (annotation instanceof window.PSPDFKit.Annotations.RectangleAnnotation) {
            return true;
          }

          if (annotation instanceof window.PSPDFKit.Annotations.TextAnnotation) {
            return true;
          }

          // The rest of the annotations will be rendered using their default, native appearance.
          return false;
        },
        enableAutomaticLinkExtraction: true,
        licenseKey:"c628UBRRBDRG3QO10dGC6bQn21W862JTfoavPljs1qsaLoULJUBsUaxLsYyCHJAFZF_X8bcxCMcI7ekEMo1Ge96a1bbDMfeUFT8wfSlxt9fWzSD75vQFCcUHXgRohuMYVea0udvKsdPNS69fEtRC_eK5AHy0T5tXokvEkKmimzzxBxBqeg1R8l4e96jTdi-Ktg4-voit6d0Y0n3OT8JtCYqT9zQ2A-G5fLEdi_DyY801_6acZ8BbEFKbmQuoUTLBquGgBBkRNxVu8NQqSYGzdMV6G6UiTAD1zgjl3zFAM72RXdEVs7I5Lzbgh3wM3DGJMn19RfEmnj259GCCHRMTZfc1NLZ_xQ7X7EK0LHHQn5vfU8-ujNseei_gApmUEVtv9s7l1EswCJ6lpNA2mbHVqvAOYqN9Ry7FWZVwdeWCoCNxBfvsux3YBb83ucz7ShGGQs92dHCzhExAqO8xOghc_tMFbggqFzVdXDwlGR-7kPQhXF3R7HXxDcCalULjSprrimDUIeapowib9odQSGxT1Ah4Gv0aYgslcClAzZOEnlU="

      });

 
      setTimeout(() => {
        this._instance.setViewState(viewState => 
          viewState.set("currentPageIndex", 0)
        );
      }, 50)

      this._instance.setViewState(viewState =>
        viewState.merge({
          sidebarMode: window.PSPDFKit.SidebarMode.DOCUMENT_OUTLINE,
          viewportPadding:{horizontal:1, vertical:1},
          scrollMode:window.PSPDFKit.ScrollMode.PER_SPREAD,
          zoom: window.PSPDFKit.ZoomMode.FIT_TO_WIDTH,
          readOnly:false
        })
      );

      this._instance.addEventListener("annotations.create", (createdAnnotations) => {

        setTimeout(() =>{
          if(this.state && this.state.zoomTo){
            const padding = 10
            this._instance.jumpAndZoomToRect(this.state.zoomTo.pageIndex, this.state.zoomTo.boundingBox.grow(padding));
   
            this.setState({zoomTo:null})
            //this.setState({zoomTo:null})

          } 
        }, 50)
       
      });

      this._instance.setToolbarItems(items => {
        let arr = [
          {type: "sidebar-thumbnails"},
          {type: "sidebar-document-outline"},
          {type: "sidebar-annotations"},
          {type: "sidebar-bookmarks"},
          {type: "pager"},
          {type: "pan"},
          {type: "zoom-out"},
          {type: "zoom-in"},
          {type: "zoom-mode"},
          {type:"marquee-zoom"},
          {type:"spacer" },
          {type: "ink"},
          {type: "highlighter"},
          {type: "text-highlighter"},
          {type: "ink-eraser"},
          {type: "ink-signature"},
          {type: "image"},
          {type: "stamp"},
          {type: "note"},
          {type: "text"},

          {type: "line"},
{type: "arrow"},
 
        {type: "ellipse"},
        {type: "polygon"},
        {type: "polyline"},
        {type: "print"},
        {type: "document-editor"},
        {type: "search"},
        {type: "debug"},
        {
          type: "custom",
          id: "my-button",
          title: "Ladda ner filen",
          onPress: event => {
            window.open("https://tqi.qte.nu/documents/" + this.state.tag + "/pdf?jwt=" + this.state.token, '_blank');
          }
        },
        {
          type: "custom",
          id: "my-button",
          title: "Hämta & skriv ut valda sidor",
          onPress: event => {
            this.setState({downloadParts:true})

          }
        }
]; 


 return arr
      });


      let outline = await this._instance.getDocumentOutline()
      
      let elements = this._instance.contentDocument.getElementsByClassName("PSPDFKit-Expando-Control") 
      let pdfControl ={}
      outline._tail.array.map((a) => {
        a._values._tail.array.map((b) => {

          if(typeof(b) === "object" && b._tail && b._tail.array){
               b._tail.array.map((c) => {
                
                let pdf =  c._values._tail.array[6] ? c._values._tail.array[6]._values._tail.array[0] : null 
                if(pdf){
                  pdfControl[c._values._tail.array[1]] = pdf;
                }
                
              })
          }
        })
      }) 


      for(var i = 0; i < elements.length; i++){
        let element = elements[i]
        elements[i].addEventListener("click", (e) => {
          let name = element.children[0].children[0].children[0].innerHTML.replace("amp;", "")
        
          
          if(pdfControl[name] && typeof(pdfControl[name]) === "string" && pdfControl[name].endsWith(".pdf")){
              window.location.href = window.location.href.substring(0, window.location.href.lastIndexOf("/") + 1)+pdfControl[name]
          }
        });
       }
    } catch (e) {
      console.log(e);
    }

    

  }

  unload() {
    window.PSPDFKit.unload(this._instance || this._container);
    this._instance = null;
  }

  changeScrollView(){
  
    this._instance.setViewState(viewState =>
      viewState.merge({
        zoom: window.PSPDFKit.ZoomMode.FIT_TO_WIDTH,

        scrollMode: this.state.scroll ? window.PSPDFKit.ScrollMode.PER_SPREAD : window.PSPDFKit.ScrollMode.CONTINUOUS,
        
      })
    );
    this.setState({scroll: !this.state.scroll})
  }

  download(instance){
    instance.exportPDF().then(function(buffer) {
      
      const supportsDownloadAttribute = HTMLAnchorElement.prototype.hasOwnProperty(
        "download"
      );
      const blob = new Blob([buffer], { type: "application/pdf" });
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, "download.pdf");
      } else if (!supportsDownloadAttribute) {
        const reader = new FileReader();
        reader.onloadend = function() {
          const dataUrl = reader.result;
          downloadPdf(dataUrl);
        };
        reader.readAsDataURL(blob);
      } else {
        const objectUrl = window.URL.createObjectURL(blob);
        downloadPdf(objectUrl);
        window.URL.revokeObjectURL(objectUrl);
      }
    });
    function downloadPdf(blob) {
      const a = document.createElement("a");
      a.href = blob;
      a.style.display = "none";
      a.download = "download.pdf";
      a.setAttribute("download", "download.pdf");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  savePdf() {
    
    this._instance.exportPDF().then(buffer => {
      const blob = new Blob([buffer]);
      var file = new File([blob], this.props.pdfName, {
        path: this.props.pdfName,
        type: "application/pdf"
      });
      const reader = new FileReader();
      reader.readAsDataURL(file);

      (async () => {
        if (this.props.isAuthenticated) {
          const token = await this.props.getTokenSilently();
          if (token) {
            const postFileUrl = await UploadFileService(
              this.props.folderPath,
              file.name,
              this.props.companyId,
              token
            );

            const response = await axios.put(postFileUrl, file);
            this.setState({
              saved: true
            });
          }
        }
      })();
    }); // => ArrayBuffer
    // // this._instance.exportInstantJSON().then(function(instantJSON) {
    // //   // Persist it to a server
    // //   console.log(instantJSON);
    // //   // fetch("https://example.com/annotations", {
    // //   //   "Content-Type": "application/json",
    // //   //   method: "POST",
    // //   //   body: JSON.stringify(instantJSON)
    // //   // }).then(...);
    // // });
  }

  // async bookmarksToUppercase() {
  //   const bookmarks = await this._instance.getBookmarks();
  //   const uppercasedBookmarks = bookmarks.map(bookmark =>
  //     bookmark.set("name", bookmark.name.toUpperCase())
  //   );
  //   console.log("bookmarks?: ", uppercasedBookmarks);
  // }

  componentDidMount() {
    this.load(this.props);
 
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    // We only want to reload the document when the pdfUrl prop changes.
    if (nextProps.pdfUrl !== prevProps.pdfUrl) {
      this.unload();
      this.load(nextProps);
    }
  }

  componentWillUnmount() {
    this.unload();
  }

  async downloadParts() {
    if(Number(this.state.start) > Number(this.state.end)){
      this.setState({showError:true})
      return
    }
    const token = await this.props.getTokenSilently();
    
    this.setState({loading:true})
    GetPartsOfFile(this.state.start, this.state.end,"https://tqi.qte.nu/documents/" + this.state.tag + "/pdf?jwt=" + this.state.token,token ).then((res) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application/pdf;base64," + res; //Image Base64 Goes here
        a.download = "a.pdf"; //File name Here
        a.click(); //Downloaded file 
        this.setState({loading:false})

    })
  }

  render() {
    // console.log("annotation: ", this.annotation);
    // console.log("serializedObject: ", this.serializedObject);
    // console.log("json: ", this.json);
   
    return (
      <>
        <Dialog
      open={this.state.downloadParts}
      onClose={() => {}}
      
    >
      <DialogTitle>Skriv ut delar av filen</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        {
          this.state.loading ? (<h2>Hämtar filen...</h2>) : (
            <FormControl fullwidth="true" >
              {
                this.state.showError ? (<p>Startsiffran måste vara lägre än slutsiffran</p>) : null
              }
              
          <FormControl>
            <InputLabel>Start</InputLabel>
            <Input
              value={this.state.start}
              type="number"
              onChange={e => {
                this.setState({start: e.target.value})
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Slut</InputLabel>
            <Input
              value={this.state.end}
              type="number"
              onChange={e => {
                this.setState({end:e.target.value})
              }}
            />
          </FormControl>
        </FormControl>
          )
        }
        
      </DialogContent>
      <DialogActions>
        <Button
         
         onClick={() => {this.setState({downloadParts:false})}}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
         disabled={this.state.loading}
         onClick={this.downloadParts}
          color="primary"
          variant="contained"
        >
          Skriv ut
        </Button>
      </DialogActions>
    </Dialog>
        
        <div
          ref={this.onRef}
          style={{ width: "100%", height: "100vh", position: "relative" }}
        />
      </>
    );
  }
}

import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetCompanies } from "../../Services/Companies/companiesHooks";
import { CompaniesPageHeader } from "./CompaniesPageHeader";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import CompaniesList from "./CompaniesList";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const CompaniesListPage = () => {
  const companies = useSelector(state => state.companies.companies);

  const [createResponse, setCreateResponse] = useState("");
  // const [companies] = useGetCompanies(createResponse)

  const [currentPage, setCurrentPage] = useState(0);
  const [company, loadedPages, loading, error] = useGetCompanies(
    currentPage,
    companies[0].id,
    createResponse
  );

  const [currentCompanies, setCurrentCompanies] = useState(companies);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    getNumberOfPage(value);
  };

  useEffect(() => {
    if (company) {
      setCurrentCompanies(company);
    }
  }, [company]);

  const callbackUl = useCallback(value => {
    setCreateResponse(value);
  }, []);

  const getNumberOfPage = page => {
    setCurrentPage(page - 1);
  };

  return (
    //    <Container>
    //         <CompaniesPageHeader callbackUl={callbackUl}/>
    //         < TableContainer component={Paper} >
    //             <Table aria-label="simple table">
    //                 <TableHead>
    //                     <TableRow>
    //                         <TableCell>Namn</TableCell>
    //                         <TableCell align="right">Id</TableCell>
    //                         <TableCell align="right">Datum&nbsp;(skapad)</TableCell>
    //                     </TableRow>
    //                 </TableHead>

    //                 <TableBody>
    //                     {currentCompanies
    //                         ? currentCompanies.map(company => (
    //                             <TableRow key={company.id}>
    //                                 <TableCell component="th" scope="row">
    //                                     <Link
    //                                         style={{
    //                                             activeStyle: "none",
    //                                             textDecoration: "none"
    //                                         }}
    //                                         to={`/companies/${company.id}/`}
    //                                     >
    //                                         {company.name}
    //                                     </Link>
    //                                 </TableCell>
    //                                 <TableCell align="right">{company.id}</TableCell>
    //                                 <TableCell align="right">
    //                                     {formatDate(company.created)}
    //                                 </TableCell>
    //                             </TableRow>
    //                         ))
    //                         : null}
    //                 </TableBody>
    //             </Table>
    //         </TableContainer >
    //         <div style={{ paddingTop: "30px" }}>
    //             <Grid container spacing={2} justify="center">
    //                 <Pagination
    //                     count={loadedPages}
    //                     page={currentPage + 1}
    //                     onChange={handleChange}
    //                 />
    //             </Grid>
    //         </div>
    //     </Container>
    <Container>
      <CompaniesPageHeader callbackUl={callbackUl} />

      <>
        {/* <Grid container justify="center">
                        {" "}
                        <div style={{ paddingBottom: "20px" }}>
                            <SearchBox></SearchBox>
                        </div>
                    </Grid> */}

        <Grid container spacing={2}>
          <CompaniesList
            currentPage={currentPage}
            loadedData={currentCompanies}
            loadedPages={loadedPages}
            loading={loading}
            getNumberOfPage={getNumberOfPage}
            // searchInput={searchInput}
            // getSearchInput={getSearchInput}
            // handleSubmit={handleSubmit}
          />
        </Grid>
        <div style={{ paddingTop: "30px" }}>
          <Grid container spacing={2} justify="center">
            <Pagination
              count={loadedPages}
              page={currentPage + 1}
              onChange={handleChange}
            />
          </Grid>
        </div>
      </>
    </Container>
  );
};

export default CompaniesListPage;

import { useState, useEffect } from "react";
import { GetEstatesService, GetEstateService, GetEstatesMarkers } from "../Estates/estateServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setEstates } from "../../Redux/actions/estatesActions";
import { useAuth0 } from "../../react-auth0-spa";

export const useGetEstates = (page, companyId, size = 20) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const [allEstates, setAllEstates] = useState(null);
  const [allPages, setAllPages] = useState(null);
  const [allEstateLoading, setAllEstateLoading] = useState(false);
  const [allEstateError, setAllEstateError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        setAllEstateLoading(true);
        const token = await getTokenSilently();
        if (token) {
          const resp = await GetEstatesService(page, companyId, token, size);
          if (!resp) {
            setAllEstateError(resp);
            setAllEstateLoading(false);
            return;
          }
          setAllEstateLoading(false);
          setAllEstateError(null);
          setAllPages(resp.totalPages);
          setAllEstates(resp.content);
          dispatch(setEstates(resp.content));
        }
      }
    })();
  }, [page, dispatch, companyId, isAuthenticated, size]);

  return [allEstates, allPages, allEstateLoading, allEstateError];
};

export const useGetEstate = (id, companyId, editedEstate) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();

  const [estate, setEstate] = useState(null);
  const [estateLoading, setEstateLoading] = useState(false);
  const [estateError, setEstateError] = useState();

  const estates = new Map(
    useSelector(state => state.estates.estates).map(i => [i.id, i])
  );
  const maybeEstate = estates.get(Number(id));
  const dispatch = useDispatch();

  useEffect(() => {
    if (maybeEstate) {
      setEstate(maybeEstate);
    }
  }, [maybeEstate, editedEstate]);

  useEffect(() => {
    (async () => {
      if (maybeEstate && !editedEstate) {
        setEstate(maybeEstate);
        return;
      }
      if (isAuthenticated) {
        setEstateLoading(true);

        const token = await getTokenSilently();
        if (token) {
          const resp = await GetEstatesMarkers(companyId, token);
          if (!resp) {
            setEstateError(resp);
            setEstateLoading(false);
          }
          let filteredData = resp.filter(
            data => data.id === parseInt(id)
          )[0];
          setEstate(filteredData);
          setEstateError(null);
          setEstateLoading(false);
        }
      }
    })();
  }, [id, dispatch, maybeEstate, editedEstate, isAuthenticated]);

  return [estate, estateLoading, estateError];
};

import { GET_ESTATES, ADD_ESTATE, REMOVE_ESTATES } from "../actions/types";

const initialState = {
  estates: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ESTATES:
      if (state.estates) {
        var result = new Map(state.estates.map(i => [i.id, i]));
        action.payload.estates.forEach(estate => {
          result.set(Number(estate.id), estate);
        });
      }
      return {
        ...state,
        estates: state.estates
          ? [...Array.from(result.values())]
          : action.payload.estates
      };

    case ADD_ESTATE:
      if (state.estates) {
        var resultAdd = new Map(state.estates.map(i => [i.id, i]));
        resultAdd.set(Number(action.payload.estate.id), action.payload.estate);
      }
      return {
        ...state,
        estates: [...Array.from(resultAdd.values())]
      };
    case REMOVE_ESTATES:
      return {
        ...state,
        estates: []
      };
    default:
      return state;
  }
}

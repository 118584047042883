import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Geocode from "react-geocode";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: props.points ? this.getBounds(props.points) : null,
      markers: [],
      isAdding: false,
      zoom: props.markers.length === 1 ? 10 : 6
    };
    Geocode.setApiKey("AIzaSyDL94a2o87vZYVP7-h8q9pmcT1IxuGQTFc");
  }

  displayMarkers = () => {
    if (this.state.isAdding) {
      return
    }
    this.setState({ markers: [], isAdding: true })
    Promise.all(this.props.markers.map((mark, index) => {
      return new Promise((resolve, reject) => {
        if (!mark.latitude) {
          return null;
        }

        //Geocode.fromAddress(mark.address).then((address) => {
        //if(address.results.length>0){
        // let result = address.results[0].geometry.location
        // mark.lat = result.lat
        // mark.lng = result.lng
        //}
        mark.lat = mark.latitude
        mark.lng = mark.longitude
        resolve(mark)
        //})
      })
    })).then((res) => {
      this.setState({ markers: res.filter(mark => mark), isAdding: false })

    })
  };

  componentDidUpdate(u) {
    if (u.markers !== this.props.markers) {
      this.setState({ markers: [] })
      this.displayMarkers()
      this.setState({ bounds: this.props.points ? this.getBounds(this.props.points) : null })

    }
  }

  getBounds = points => {
    
    let bounds = new this.props.google.maps.LatLngBounds();
    if (points) {
      for (var i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
      }
    }
    return bounds;
  };

  render() {
    // console.log("lng: ", this.props.lat);
    // console.log("long: ", this.props.long);
    // console.log("markers: ", this.props.markers);
    // console.log("points: ", this.props.points);
    // var bounds = new this.props.google.maps.LatLngBounds();
    // let points = this.props.points;
    // if (points) {
    //   for (var i = 0; i < points.length; i++) {
    //     bounds.extend(points[i]);
    //   }
    // }
    // console.log("im updating", this.props.lat)
    return (
      <Map
        center={this.props.markers.length === 1 ? { lat: this.props.markers[0].lat, lng: this.props.markers[0].lng } : {}}
        initialCenter={{ lat: 59.3331369, lng: 18.087286 }}
        google={this.props.google}
        //zoom={this.state.zoom}
        bounds={this.bounds}
      //onBounds_changed={(e) => {
      //  console.log(e)
      //  this.setState({bounds: e})
      //e?.onZoom_changed(e1 => {
      //})
      //this.setState({zoom: e.zoom})
      //}}
      >
        {this.state.markers.map((marker) => {
          return (
            <Marker
              key={marker.id}
              position={{
                lat: marker.lat,
                lng: marker.lng
              }}
              onClick={() => this.props.selectEstate(marker)}
            />
          );
        })}
      </Map>
    );
  }
}

class NewMapContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bounds: null,
    }

    this.calculateState = this.calculateState.bind(this)
    this.calculateState(props)
  }

  componentWillReceiveProps(props) {
    if(this.props.markers != props.markers) {
      this.calculateState(props)
    }
  }


  calculateState(props) {
    if(props.markers.length >= 1) {
      const bounds = this.getBounds(props.markers);

      this.setState({
        bounds: bounds,
      })
    }
  }

  getBounds = points => {
    let bounds = new this.props.google.maps.LatLngBounds();



    if (points) {
      for (var i = 0; i < points.length; i++) {
        bounds.extend(new this.props.google.maps.LatLng(points[i].latitude, points[i].longitude));
      }
    }
    return bounds // ;
  };

  render() {
    let center = { lat: 59.3331369, lng: 18.087286 }
    let bounds = this.state.bounds;
    let zoom = this.state.bounds ? undefined : 10;
    if (this.props.singleMarker) {
      if(this.props.markers[0]) {
        center = {
          lat: this.props.markers[0].latitude,
          lng: this.props.markers[0].longitude,
        }
      }
     
      bounds = undefined;
      zoom = 10;

    }
    return (
      <div>
        <Map
          bounds={bounds}
          google={this.props.google}
          initialCenter={center}
          zoom={zoom}
          onBounds_changed={(e) => {
            this.setState({ bounds: e.bounds })
          }}
        >
          {this.props.markers.map((marker) => {
            return (
              <Marker
                key={marker.id}
                position={{
                  lat: marker.latitude,
                  lng: marker.longitude
                }}
                onClick={() => this.props.selectEstate(marker)}
              />
            );
          })}
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDL94a2o87vZYVP7-h8q9pmcT1IxuGQTFc"
})(NewMapContainer);

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { PostCompanyService } from "../../Services/Companies/companiesServices";
import { addCompany } from "../../Redux/actions/companyActions";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "550px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },

  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const CreateCompaniesUser = ({ open, onClose, callbackUl }) => {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState("");
  const [orgNumber, setOrgNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [createCompaniesNameError, setCreateCompaniesNameError] = useState(false);
  const [createCompaniesOrgnumberError, setCreateCompaniesOrgnumberError] = useState(false);
  const dispatch = useDispatch();
  const {companyID} = useParams();
  const companyId = Number(companyID);


  useEffect(() => {
    setLoading(false);
  }, [open]);
  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  useEffect(() => {
    if(companyName === "") {
      setCreateCompaniesNameError(true);
    }
    if(orgNumber === "") {
      setCreateCompaniesOrgnumberError(true);
    }
  }, [companyName, orgNumber]);

  const createCompany = async () => {
    setLoading(true);
    const resp = await PostCompanyService(companyId, {
      name: companyName,
      orgNumber: orgNumber
    });
    dispatch(addCompany(resp));
    setCompanyName("");
    callbackUl(resp);
    setLoading(false);
    onClose();
  };

  const companyNameHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      return;
    } else {
      setCompanyName(value);
      setCreateCompaniesNameError(false);
    }
  };

  const companyOrgHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      return;
    } else {
      setOrgNumber(value);
      setCreateCompaniesOrgnumberError(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Skapa organisation</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%" }}
        >
          <FormControl>
            <InputLabel>Organisationsnamn</InputLabel>
            <Input
              onChange={e => {
                companyNameHandler(e.target.value);
              }}
              error={createCompaniesNameError}
            />
          </FormControl>

          <FormControl>
            <InputLabel>Org-nummer</InputLabel>
            <Input
              onChange={e => {
                companyOrgHandler(e.target.value);
              }}
              error={createCompaniesOrgnumberError}
            />
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          onClick={createCompany}
          color="primary"
          variant="contained"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCompaniesUser;

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link, Typography, Input, TextField,
  MenuItem } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import EmptyFolderIcon from "@material-ui/icons/FolderOpen";
import { Link as Routerlink } from "react-router-dom";
import FileIcon from "@material-ui/icons/Description";
import FileCopy from "@material-ui/icons/FileCopy";
import { OpenFileService } from "../../Services/Folders/folderServices";
import { DeleteFileService, ChangeFolderNameService, CopyFolderService } from "../../Services/Folders/folderServices";
import {GetEstatesService, GetEstatesMarkers } from "../../Services/Estates/estateServices";
import {ReadPDFService, GetPartsOfFile} from './../../Services/Folders/folderServices'

import bytesToSize from "../../Functions/bytesToSize";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditIcon from "@material-ui/icons/Edit";
import DownloadBtn from "@material-ui/icons/CloudDownload";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { useOpenFile } from "../../Services/Folders/folderHooks";
import { useAuth0 } from "../../react-auth0-spa";
import formatDate from "../../Functions/formatDate";

export const EstateFileTable = ({
  files,
  fileLoading,
  fileViewHandler,
  callbackUl,
  checkedFileIds,
  setCheckedFileIds,
}) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const { pathname } = useLocation();
  const { companyID, path } = useParams();
  const companyId = Number(companyID);
  const [deleteFileDialog, setDeleteFileDialog] = useState(false);
  const [deleteFile, setDeleteFile] = useState();
  const [deleteFileName, setDeleteFileName] = useState();
  const [deleteFolderDialog, setDeleteFolderDialog] = useState(false);
  const [deleteFolder, setDeleteFolder] = useState();
  const [deleteFolderName, setDeleteFolderName] = useState();
 
  const [changeFolderName, setChangeFolderName] = useState()
  const [changeFolderNameDialog, setChangeFolderNameDialog] = useState(false);
  const [copyFolderDialog, setCopyFolderDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState("")
  
  const [currentEstateState, setCurrentEstate] = useState([])
  const [newEstateName, setNewEstateName] = useState("");
  const [copyFolderObj, setCopyFolderObj] = useState()
  const [copyCompany, setCopyCompany] = useState(companyId)
  
  useEffect(() => {
    (async  () => {
      
        let token = await getTokenSilently()
        setCurrentEstate((await GetEstatesMarkers(copyCompany,token)));
      

    })()
    
    
  }, [copyCompany])

  const [changeName, setChangeName] = useState(false)

  const companies = useSelector(state => state.companies.companies);
  const userState = useSelector(state => state.user.user)
  const companiesFiltered = companies.filter(company => company.id === Number(companyID))[0]
  const role = companiesFiltered ? companiesFiltered.users.filter((userObj) => userObj.userId === userState.sub)[0].userRole : null
  const isAdmin =  role === "ADMIN" || role === "SUPER_ADMIN"


  const deleteFileHandler = () => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {

          DeleteFileService(companyId, deleteFile.key, token).then(() =>{
              callbackUl(deleteFile.key)
              deleteFileDialogClose();
            }
          
          );
        }
      }
    })();
  };

  const deleteFileDialogOpen = (file, name) => {
    setDeleteFile(file);
    setDeleteFileName(name);
    setDeleteFileDialog(true);
  };

  const deleteFileDialogClose = () => {
    setDeleteFileDialog(false);
  };

  const deleteFolderHandler = () => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          DeleteFileService(companyId, deleteFolder.folder, token).then(() =>
          {
            callbackUl(deleteFolder.key)
            deleteFolderDialogClose();

          }

          );
        }
      }
    })();
  };

  const changeFolderNameHandler = (folder, newName) => {
    (async () => {

      let splittedFolder = folder.split("/");
      if(splittedFolder[splittedFolder.length-1]=== newName){
        
        return
      }

      let Nname = encodeURIComponent(newName.replace(/_/g, " "))
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token && !changeName) {
          setChangeName(true)
          ChangeFolderNameService(folder, companyId,Nname,token)
          .then((e) => {
            setChangeFolderNameDialog(false)
            callbackUl()
            setChangeName(false)

          }
            );
         
        }
      }
    })();
  }

  const deleteFolderDialogOpen = (folder, name) => {
    setDeleteFolder(folder);
    setDeleteFolderName(name);
    setDeleteFolderDialog(true);
  };

  const copyFolder = async (folder, foldername, estate) => {
    const token = await getTokenSilently();
    CopyFolderService(folder, copyCompany, foldername,estate, token).then((res) => {
      setCopyFolderDialog(false)
    });
  }

  const changeFileNameDialog = (folder, name) => {

    setChangeFolderNameDialog(true)
    setChangeFolderName(folder)
    let Nname = decodeURIComponent(name.replace(/_/g, " "))
    setNewFolderName(Nname)
  };

  const deleteFolderDialogClose = () => {
    setDeleteFolderDialog(false);
  };

  let basePath = pathname.endsWith("/") ? pathname : pathname + "/";
  // basePath = basePath.split("/").map(x => encodeURIComponent(x)).join("/")
  // console.log("files: ", files);
  return (
    <>
      <Dialog open={deleteFileDialog} onClose={deleteFileDialogClose}>
        <Typography
          style={{ padding: "10px" }}
        >{`Vill du ta bort ${deleteFileName ? decodeURIComponent(deleteFileName.replace(/_/g, " ")) : null}?`}</Typography>
        <Button onClick={deleteFileHandler} color="secondary">
          Ta bort
        </Button>
      </Dialog>
      <Dialog open={deleteFolderDialog} onClose={deleteFolderDialogClose}>
        <Typography
          style={{ padding: "10px" }}
        >{`Vill du ta bort ${deleteFolderName ? decodeURIComponent(deleteFolderName.replace(/_/g, " ")) : null}?`}</Typography>
        <Button onClick={deleteFolderHandler} color="secondary">
          Ta bort
        </Button>
      </Dialog>

      <Dialog open={changeFolderNameDialog} onClose={() => setChangeFolderNameDialog(false)}>
        <Typography
          style={{ padding: "10px" }}
        >{`Byt namn`}</Typography>
        <Input    value={newFolderName} onChange={(e) =>{
          if (/[\\|\/<>_%#]+$/.test(e.target.value)) {
            return;
          } else {
            setNewFolderName(e.target.value)
          }
        }} style={{ padding: "10px", width:400 }}  />
        <Button onClick={() => changeFolderNameHandler(changeFolderName.folder.substring(0, changeFolderName.folder.length - 1),newFolderName)} >
          Byt namn
        </Button>
      </Dialog>

      <Dialog open={copyFolderDialog} onClose={() => setCopyFolderDialog(false)}>
        <Typography style={{ padding: "10px" }} >Kopiera till fastighet</Typography>
        <TextField
            id="standard-select-currency"
            select
            label="Välj företag"
            value={copyCompany}
            onChange={(value) => {setCopyCompany(value.target.value)}}
            helperText=""
          >
            {companies.map(option =>  (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </TextField> 
          
        <TextField
            id="standard-select-currency"
            select
            label="Välj fastighet"
            value={newEstateName}
            onChange={(value) => {setNewEstateName(value.target.value)}}
            helperText=""
          >
            {currentEstateState.map(option =>  (
              <MenuItem key={option} value={option.folderName}>
                {option.name}
              </MenuItem>
            ))}
        </TextField> 
          
          <Button onClick={() => copyFolder(copyFolderObj.folder.folder, copyFolderObj.relativePath, newEstateName)} >
            Kopiera
          </Button>
      </Dialog>


      <section>
        <table
          style={{
            width: "100%",
            textAlign: "left",
            borderSpacing: "0 0.5rem",
            borderCollapse: "separate"
          }}
        >
          <thead>

            <tr>
            <th style={{}}><Checkbox checked={checkedFileIds.length == (files.folders.length + files.files.length) && checkedFileIds.length != 0} onChange={(_e, checked) => {
                        if(checked) {
                          //setCheckedFileIds((state) => [...state, folder.folder])
                          if(checkedFileIds.length > 0){
                            setCheckedFileIds([])
                          } else {
                            setCheckedFileIds(files.folders.map((f) => f.folder).concat(files.files.map((f) => f.key)))
                          }
                        } else {
                          setCheckedFileIds([])

                        }
                      }} style={{ padding: 0, margin: 0}} color="primary" /></th>
              <th>Namn</th>
              <td>Storlek</td>
              <td>Skapad</td>
            </tr>
          </thead>
          <tbody>
            {fileLoading
              ? null
              : files.folders.map(folder => {
                  let relativePath = folder.folder
                    .replace(/\/$/, "")
                    .split("/")
                    .pop();

                    let relativePathLink = encodeURIComponent(relativePath);
                    const checked = checkedFileIds.includes(folder.folder)


                  return (
                    <tr className="elementToFadeIn" key={relativePath}>
                      <td style={{}}><Checkbox checked={checked} onChange={(_e, checked) => {
                        if(checked) {
                          setCheckedFileIds((state) => [...state, folder.folder])
                        } else {
                          let a = [...checkedFileIds]
                          const index = a.indexOf(folder.folder);
                          if (index !== -1) {
                            a.splice(index, 1);
                            setCheckedFileIds(() => a)
                          }
                        }
                      }} style={{ padding: 0, margin: 0}} color="primary" /></td>
                      <th style={{ fontWeight: "inherit" }}>
                        <Link
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color:"black"
                          }}
                          color="inherit"
                          to={`${basePath}${relativePathLink}`}
                          component={Routerlink}
                        >
                          {folder.hasChildren ? (
                            <FolderIcon style={{ marginRight: 8 }} />
                          ) : (
                            <EmptyFolderIcon style={{ marginRight: 8 }} />
                          )}

                          {decodeURIComponent(relativePath.replace(/_/g, " "))}
                        </Link>
                      </th>
                      <td></td>
                      <td></td>
                      <td>
                        {!isAdmin ? null : (
                          <DeleteRoundedIcon
                            color="secondary"
                            fontSize="small"
                            onClick={() =>
                              deleteFolderDialogOpen(folder, relativePath)
                            }
                          ></DeleteRoundedIcon>
                        )}
                      </td>
                      <td><FileCopy
                            color="black"
                            fontSize="small"
                            onClick={() =>{
                              setCopyFolderDialog(true)
                              setCopyFolderObj({folder, relativePath})
                              
                            }
                            }
                          ></FileCopy></td>
                      <td>
                        {!isAdmin ? null : (
                          <EditIcon
                            fontSize="small"
                            onClick={() =>
                              changeFileNameDialog(folder, relativePath)
                            }
                          ></EditIcon>
                        )}
                      </td>
                    </tr>
                  );
                })}
            {fileLoading
              ? null
              : files.files.map((file, index) => {
                  const relativePath = file.key
                    .replace(/\/$/, "")
                    .split("/")
                    .pop();
                  const modifiedDate = new Date(file.lastModified);
                  const formattedDate = formatDate(file.lastModified);
                  const checked = checkedFileIds.includes(file.key)
                  return (
                    <tr className="elementToFadeIn" key={relativePath}>
                      <td style={{}}><Checkbox checked={checked} onChange={(_e, checked) => {
                        if(checked) {
                          setCheckedFileIds((state) => [...state, file.key])
                        } else {
                          let a = [...checkedFileIds]
                          const index = a.indexOf(file.key);
                          if (index !== -1) {
                            a.splice(index, 1);
                            console.log('Array', a)
                            setCheckedFileIds(() => a)
                          }
                        }
                      }} style={{ padding: 0, margin: 0}} color="primary" /></td>
                      <th style={{ fontWeight: "inherit" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                          // {/* <Link
                          //   style={{
                          //     display: "flex",
                          //     flexDirection: "row",
                          //     alignItems: "center",
                          //     cursor: "pointer"
                          //   }}
                          //   color="inherit" */}
                          onClick={e => {
                            // e.preventDefault();
                            (async () => {
                              if (isAuthenticated) {
                                const token = await getTokenSilently();
                                if (token) {
                                  OpenFileService(
                                    companyId,
                                    file.key,
                                    token
                                  ).then(resp => {
                                    const type = file.key.match(
                                      /\.[0-9a-z]+$/i
                                    );

                                    fileViewHandler(
                                      resp,
                                      index,
                                      type[0],
                                      relativePath
                                    );
                                  });
                                }
                              }
                            })();
                          }}
                        >
                          <FileIcon />
                          {relativePath}
                        </div>
                        {/* </Link> */}
                      </th>
                      <td>{bytesToSize(file.size)}</td>
                      <td>{formattedDate}</td>
                      <td>
                        {!isAdmin ? null : (
                          <DeleteRoundedIcon
                            color="secondary"
                            fontSize="small"
                            onClick={() =>
                              deleteFileDialogOpen(file, relativePath)
                            }
                          ></DeleteRoundedIcon>
                        )}
                      </td>
                      <td>
                        
                          <DownloadBtn
                            color="main"
                            fontSize="small"
                            onClick={async () => {
                              if(!file.key.toLowerCase().endsWith(".pdf")){
                                (async () => {
                                  if (isAuthenticated) {
                                    const token = await getTokenSilently();
                                    if (token) {
                                      OpenFileService(
                                        companyId,
                                        file.key,
                                        token
                                      ).then(resp => {
                                        const type = file.key.match(
                                          /\.[0-9a-z]+$/i
                                        );
    
                                        fileViewHandler(
                                          resp,
                                          index,
                                          type[0],
                                          relativePath
                                        );
                                      });
                                    }
                                  }
                                })();
                                return;
                              }

                              let filePath = file.key.split("/")
                              filePath.shift()
                              let red = await ReadPDFService(filePath.join("/") , companyID, await getTokenSilently())
                              
                              window.open("https://tqi.qte.nu/documents/" + red.tag + "/pdf?jwt=" + red.token, '_blank')
                            }}
                          ></DownloadBtn>
                        
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </section>
    </>
  );
};

export default EstateFileTable;

import { useState, useEffect } from "react";
import { GetUsersService, GetUsersDetailsService } from "../Users/userServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setEstates } from "../../Redux/actions/estatesActions";
import { setUsers } from "../../Redux/actions/userActions";
import { useAuth0 } from "../../react-auth0-spa";

export const useGetUsers = (page, companyId) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();

  const [allUsers, setAllUsers] = useState(null);
  const [allUserLoading, setAllUserLoading] = useState(false);
  const [allUserError, setAllUserError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          setAllUserLoading(true);
          const resp = await GetUsersService(token);
          if (!resp) {
            setAllUserError(resp);
            setAllUserLoading(false);
            return;
          }
          setAllUserLoading(false);
          setAllUserError(null);
          //   setAllPages(resp.totalPages);
          setAllUsers(resp.items);
          dispatch(setUsers(resp.items));
        }
      }
    })();
  }, [page, dispatch, companyId]);

  return [allUsers, allUserLoading, allUserError];
};

export const useGetUsersDetails = (
  companyId,
  compEdit,
  compEditRole,
  compAdd,
  compRemove
) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const [allUsersDetails, setAllUsersDetails] = useState(null);
  const [allUserDetailsLoading, setAllUserDetailsLoading] = useState(false);
  const [allUserDetailsError, setAllUserDetailsError] = useState();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          setAllUserDetailsLoading(true);
          const resp = await GetUsersDetailsService(companyId, token);
          if (!resp) {
            setAllUserDetailsError(resp);
            setAllUserDetailsLoading(false);
            return;
          }
          setAllUserDetailsLoading(false);
          setAllUserDetailsError(null);
          
          setAllUsersDetails(resp.items);
        }
      }
    })();
  }, [companyId, compEdit, compEditRole, compAdd, compRemove]);

  return [allUsersDetails, allUserDetailsLoading, allUserDetailsError];
};

export const useGetUser = (page, companyId) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();

  const [allUsers, setAllUsers] = useState(null);
  const [allUserLoading, setAllUserLoading] = useState(false);
  const [allUserError, setAllUserError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          setAllUserLoading(true);
          const resp = await GetUsersService(token);
          if (!resp) {
            setAllUserError(resp);
            setAllUserLoading(false);
            return;
          }
          setAllUserLoading(false);
          setAllUserError(null);
          
          setAllUsers(resp.items);
          dispatch(setUsers(resp.items));
        }
      }
    })();
  }, [page, dispatch, companyId]);

  return [allUsers, allUserLoading, allUserError];
};

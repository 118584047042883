import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core/";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

const EstateMapList = ({ loadedData, companyID }) => {
  

  const classes = useStyles();
  return (
    <List className={classes.root}>
      {loadedData ? (
        loadedData.length === 0 ? (
          <>
            <ListItem>
              <ListItemText>Du har inga fastigheter än!</ListItemText>
            </ListItem>
          </>
        ) : (
          loadedData.map(item => {
            return (
              
                <ListItem key={item.id}>
                  <Link
                  style={{color:"black"}}
                      component={RouterLink}
                      to={`/${companyID}/estates/${item.id}/${encodeURIComponent(item.folderName)}/`}
                    >
                      
                    <ListItemText primary={item.name} />
                  </Link>
                </ListItem>
            );
          })
        )
      ) : null}
    </List>
  );
};

export default EstateMapList;

import { GET_USER, GET_USERS } from "./types";

export const setUser = user => ({
  type: GET_USER,
  payload: { user: user }
});

export const setUsers = users => ({
  type: GET_USERS,
  payload: { users: users }
});

import {
  GET_COMPANIES,
  CHANGE_COMPANY,
  ADD_COMPANY,
  GET_COMPANY
} from "./types";

export const setCompanies = companies => ({
  type: GET_COMPANIES,
  payload: { companies: companies }
});

export const setCompany = company => ({
  type: GET_COMPANY,
  payload: company
});

export const changeCompanies = company => ({
  type: CHANGE_COMPANY,
  payload: { company }
});

export const addCompany = company => ({
  type: ADD_COMPANY,
  payload: company
});

import { GET_COMPANIES, GET_COMPANY, ADD_COMPANY } from "../actions/types";

const initialState = {
  company: null,
  companies: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES:
      return { ...state, companies: [...action.payload.companies] };

    case GET_COMPANY:
      if (!action.payload) {
        return { ...state };
      } else {
        return { ...state, company: action.payload };
      }

    case ADD_COMPANY:
      return {
        ...state,
        companies: [...state.companies, action.payload]
      };
    default:
      return state;
  }
}

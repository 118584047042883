import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Input, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCompany } from "../../Redux/actions/companyActions";
import {postSupport} from '../../Services/Support/support'
import { useState } from "react";
import { useAuth0 } from "../../react-auth0-spa";


const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    // margin: theme.spacing(1),
    // marginLeft: "auto",
    width: "100%",
    maxWidth: "50rem",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center"
    alignItems: "center"
  },
  textField: {
    width: "75%"
    // flex: 1
  },
  email: {
    width: "75%",
    marginBottom: 16
  },
  button: {
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "50%"
  }
}));

const SupportPage = () => {
  const classes = useStyles();
  const { id } = useParams();

  const dispatch = useDispatch();
  let [body, setBody] = useState("");
  let [from, setFrom] = useState("");
  let [send, setSend] = useState(false);
  const {
    getTokenSilently
  } = useAuth0();

  useEffect(() => {
    dispatch(setCompany(id));
  }, []);

  async function post(){
    
    await postSupport(from, body, await getTokenSilently())
    setSend(true)
  }


  return (
    <div className={classes.container}>
      <Typography variant="h5">Vad behöver du hjälp med?</Typography>
      {/* <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Ärende"
          variant="outlined"
          multiline
          rows="10"
        />
        <Button variant="contained" color="primary" className={classes.button}>
          Skicka
        </Button>
      </form> */}
      {
        send ? (
            <Typography variant="h5" style={{marginTop:20}}>Tack för att du kontaktar oss! Vi svarar dig så fort vi kan.</Typography>
        ) : (
          <form className={classes.root} name="contact" >
          <Input variant="outlined" className={classes.email} type="email" name="email" value={from} onChange={(a) => setFrom(a.target.value)} placeholder="Email" />
  
          {/* <label> */}
          {/* <Typography>Meddelande</Typography> */}
          {/* <input type="text" name="info" /> */}
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="Meddelande"
            variant="outlined"
            multiline
            rows="10"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
          {/* </label> */}
  
          <Button color="primary" variant="contained"  onClick={post}>Send</Button>
        </form>
        )
      }
     
    </div>
  );
};

export default SupportPage;

import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { UploadFileService } from "../../Services/Folders/folderServices";
import { useDropzone } from "react-dropzone";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import FileIcon from "@material-ui/icons/Description";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { useGetFolder } from "../../Services/Folders/folderHooks";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

export default function MyDropzone({
  handleClose,
  callbackUl,
  folderPath,
  handleProgress,
  uploadHandler,
  uploadFileNameHandler
}) {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const [fileDump, setFileDump] = useState([]);
  const { companyID } = useParams();
  const companyId = Number(companyID);
  const [currentFiles, setCurrentFiles] = useGetFolder(
    folderPath,
    "",
    companyId
  );
  const isAdmin = useSelector(state => state.admin.admin);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        checkDuplicate(file);
        setFileDump(state => [...state, file]);
      };
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [fileWarning, setFileWarning] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  const deleteFileFromList = file => {
    const newDump = fileDump.filter(f => f.path !== file.path);
    setFileDump(newDump);
  };

  async function checkDuplicate() {
    let value = false;
    if (currentFiles) {
      fileDump.forEach(file => {
        currentFiles.files.forEach(oldFile => {
          if (file.name === oldFile.key.split("/").pop()) {
            setDuplicates(state => [file.name, ...state]);
            value = true;
          }
        });
      });
    }
    return value;
  }

  async function handleClick(overwrite) {
    setUploadingFile(true);
    overwrite = overwrite || false;
    if (!overwrite) {
      const checkStop = await checkDuplicate();
      if (checkStop) {
        setFileWarning(true);
        return;
      }
    }
    handleClose();
    handleProgress(0);
    uploadHandler(true);
    fileDump.forEach(file => {
      (async () => {
        if (isAuthenticated) {
          const token = await getTokenSilently();
          if (token) {
            var postFileUrl;
            
            var config = {
              onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                handleProgress(percentCompleted);
              }
            };

            var response;
            if(file.type === "application/pdf"){
              let trueFile = file.path;
              if (trueFile.startsWith("/")) {
                let newFile = trueFile.substr(1);
                trueFile = newFile;
              }
              let obj = trueFile.split('/')
              obj.splice(-1, 1);
              trueFile = obj.join("/")
              postFileUrl="https://tqi.qte.nu/webserv/s3/uploadFile?companyId=" + companyId;
              config.headers= {
                'Content-Type': 'multipart/form-data' ,
                Authorization: `Bearer ${token}`
              }
              
              if(!trueFile.endsWith("/")){
                trueFile += "/"
              }

              if(trueFile === "/"){
                trueFile =""
              }
              console.log(folderPath)
              folderPath = folderPath.split("/").map((x) => decodeURIComponent(x)).join("/")
              console.log("Folderpath", folderPath, trueFile)
              var formData = new FormData();
              formData.append("file", file);
              formData.append("path", folderPath  +"/"+ trueFile.replace(/_/g, " ")  )
              response = await axios.post(postFileUrl, formData, config);

            } else {
              console.log("this is pdf", folderPath.split("/").map((x) => encodeURIComponent(decodeURIComponent(x))).join("/"))
              postFileUrl = await UploadFileService(
                folderPath.split("/").map((x) => encodeURIComponent(decodeURIComponent(x))).join("/"),
                file.path.split("/").map((x) => encodeURIComponent(decodeURIComponent(x))).join("/"),
                companyId,
                token
              );
              response = await axios.put(postFileUrl, file, config);
            }

            
            callbackUl(response);
            handleClose();
          }
        }
      })();
    });

    uploadHandler(false);
  }

  return (
    <Grid
      container
      justify="center"
      spacing={2}
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
      }}
    >
      {fileWarning ? (
        <>
          {!isAdmin ? (
            <>
              <dialog open={fileWarning}>
                <h1 style={{ color: "red" }}>
                  Fil med samma namn finns redan:
                </h1>
                {!duplicates
                  ? null
                  : duplicates.map(name => {
                      return <p key={name}>{name}</p>;
                    })}
                <button
                  onClick={() => {
                    setFileWarning(false);
                    setDuplicates([]);
                  }}
                >
                  STÄNG
                </button>
              </dialog>
            </>
          ) : (
            <>
              <dialog open={fileWarning}>
                <h1>Vill du skriva över fil med samma namn?</h1>{" "}
                {!duplicates
                  ? null
                  : duplicates.map(name => {
                      return <p key={name}>{name}</p>;
                    })}
                <Button
                  // disabled={uploadingFile}
                  id="warning"
                  onClick={() => handleClick(true)}
                >
                  Ja
                </Button>
                <Button
                  onClick={() => {
                    setFileWarning(false);
                    setDuplicates([]);
                    setUploadingFile(false);
                  }}
                >
                  Nej
                </Button>
              </dialog>
            </>
          )}
        </>
      ) : null}
      <Typography style={{ flex: 1, marginTop: 40 }}>LÄGG TILL FIL</Typography>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          listStyle: "none"
        }}
      >
        {fileDump
          ? fileDump.map((file, index) => {
              return (
                <li style={{ padding: "10px", flex: 1 }} key={index}>
                  <Grid container spacing={1} alignContent="center" wrap="nowrap">
                    <Grid item>
                      <FileIcon fontSize="small" />
                    </Grid>
                    <Grid item>
                      {file.path}
                    </Grid>
                    <Grid item>
                      <DeleteRoundedIcon
                      color="secondary"
                      fontSize="small"
                      onClick={() => {
                        deleteFileFromList(file);
                        setUploadingFile(false);
                      }}
                    ></DeleteRoundedIcon>
                    </Grid>
                  </Grid>
                </li>
              );
            })
          : null}
      </ul>
      <div
       //elevation={3}
        {...getRootProps()}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          width: "75%",
          border: "1px solid grey",
          borderRadius: 4
        }}
      >
        <input {...getInputProps()} />
        <div
          style={{
            flex: 1,
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          {isDragActive ? (
            <p
              style={{ flex: 1, textAlign: "center" }}
              className="widget-title"
            >
              Släpp fil här
            </p>
          ) : (
            <p style={{ flex: 1, textAlign: "center", cursor: "default" }}>
              Drag 'n' drop eller klicka för att lägga till filer.
            </p>
          )}
        </div>
      </div>
      <Button
        disabled={uploadingFile || fileDump.length === 0}
        onClick={() => handleClick(false)}
        style={{ flex: 1, width: "75%", marginTop: 12, display: 'block' }}
        color={"primary"}
        variant="contained"
      >
        Ladda upp filer
      </Button>
    </Grid>
  );
}

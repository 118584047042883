import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import formatDate from "../../Functions/formatDate";
import {
  makeStyles,
  Paper,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  flex: {
    display: "flex",
    justifyContent: "space-between"
  },
  left: { marginLeft: "auto" },
  nested: {
    paddingLeft: theme.spacing(10)
  },
  table: {
    minWidth: "100%"
  },
  color: {
    backgroundColor: "#cccccc"
  }
}));

export default function Estates({
  loadedPages,
  loadedData,
  loading,
  getNumberOfPage,
  searchInput,
  getSearchInput,
  handleSubmit,
  currentPage
}) {
  const classes = useStyles();
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const createPages = p => {
      for (let i = 0; i < p; i++) {
        setPages(state => [...state, i + 1]);
      }
    };
    createPages(loadedPages);
  }, [loadedPages]);

  return (
    <>
      <Container>
        <Grid container justify="center">
          <div style={{ paddingBottom: "20px" }}>
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={e => {
                handleSubmit(e);
              }}
              style={{ display: "none" }}
            >
              {/* <TextField
                                id="standard-basic"
                                label="Search"
                                value={searchInput}
                                onChange={e => {
                                    getSearchInput(e);
                                }}
                            /> */}
            </form>
          </div>
        </Grid>
        <Grid container spacing={2}>
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Namn</TableCell>
                    <TableCell align="right">Id</TableCell>
                    <TableCell align="right">Datum&nbsp;(skapad)</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loadedData
                    ? loadedData.map(companies => (
                        <TableRow key={companies.id}>
                          <TableCell component="th" scope="row">
                            <Link
                              style={{
                                activeStyle: "none",
                                textDecoration: "none"
                              }}
                              to={`/companies/${companies.id}/`}
                            >
                              {companies.name}
                            </Link>
                          </TableCell>
                          <TableCell align="right">{companies.id}</TableCell>
                          <TableCell align="right">
                            {formatDate(companies.created)}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Container>
    </>
  );
}

import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Typography } from "@material-ui/core";
import PSPDFKit from "../../components/PSPPDFKit/PSPDFKit";
import { useAuth0 } from "../../react-auth0-spa";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { setCompany } from "../../Redux/actions/companyActions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    // margin: theme.spacing(1),
    // marginLeft: "auto",
    width: "50rem",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center"
    alignItems: "center"
  },
  textField: {
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "75%"
    // flex: 1
  },
  button: {
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "50%"
  }
}));

const PdfViewerPage = ({}) => {
  const classes = useStyles();
  const LICENSE_KEY =
    process.env.REACT_APP_PSPDFKIT_LICENSE_KEY &&
    process.env.REACT_APP_PSPDFKIT_LICENSE_KEY.trim();
    const {
      isAuthenticated,
      getTokenSilently
    } = useAuth0();

    let {companyID, path} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setCompany(companyID));
    }, []);

  return (
     <PSPDFKit
                        pdfName={"name"}

            isAuthenticated={isAuthenticated}
            getTokenSilently={getTokenSilently}
           folderPath={path}
           companyId={companyID}
          
            goBackHandler={() => {}}
            handleProgress={() => {}}
            // pdfUrl={"https://jakobs-test1584788419827.s3.eu-central-1.amazonaws.com/Test/testmapp/sample.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200325T153941Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=AKIAZXCHTQE4IWLVJEOR%2F20200325%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Signature=0d95a099180369d2b461731a8182ff487d0fd1bb0cf2ea74eb703ea9ea0907f9"}
            licenseKey={LICENSE_KEY}
          />
      
  );
};

export default PdfViewerPage;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { EditEstateService } from "../../Services/Estates/estateServices";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import PlaceSelect from "../../components/Global/PlaceSelect/PlaceSelect";
import { PostEstateService } from "../../Services/Estates/estateServices";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "550px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },

  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const CreateFolderDialog2 = ({ open, onClose, callbackUl, company }) => {
  const classes = useStyles();

  const [estateName, setEstateName] = useState("");
  const [estateId, setEstateId] = useState("");
  
  const [ writerObj, setWriter] = useState("");
  const [technical, setTechnical] = useState("");
  const [other, setOther] = useState("");

  const [loading, setLoading] = useState(false);
  const [createEstateNameError, setCreateEstateNameError] = useState(false);
  const [createEstateIdError, setCreateEstateIdError] = useState(false);
  const [createEstateAddressError, setCreateEstateAddressError] = useState(
    false
  );
  const { companyID } = useParams();
  const companyId = Number(companyID);
  const [address, setAdress] = useState("");
  const [coordinates, setCoordinates] = useState();
  const history = useHistory();

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    // setFolderName("");
    setLoading(false);
  }, [open]);

  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    setEstateName("");
    setAdress("");
    setCoordinates();
    onClose();
  };

  const createFolder = async () => {
    if (!estateName) {
      setCreateEstateNameError(true);
      return;
    }
    if (!estateId) {
      setCreateEstateIdError(true);
      return;
    }


    setLoading(true);
    let token = await getTokenSilently();
    const resp = await PostEstateService(
      {
        name: estateName,
        other:other,
        folderName: estateName.replace(" ", "-"),
        writer:writerObj,
        technical:technical,
        address: address,
        realEstateId: estateId,
        latitude: coordinates? coordinates.lat : null,
        longitude: coordinates ? coordinates.lng : null
      },
      companyId,
      token
    );
    setEstateName("");
    setAdress("");
    setCoordinates();
    callbackUl(resp);
    setLoading(false);
    onClose();
    const redirectUrl = await `/${companyID}/estates/${encodeURIComponent(resp.id)}/${encodeURIComponent(resp.folderName)}/`;
    history.push(redirectUrl);
  };

  const estateNameHandler = value => {
    
    setEstateName(value);
    setCreateEstateNameError(false);
    
  };

  const estateIdHandler = value => {
    if (/[\\|\/]+$/.test(value)) {
      return;
    } else {
      setEstateId(value);
      setCreateEstateIdError(false);
    }
  };

  const adressStateHandler = value => {
    if (/[!@#$%^&*()_+=\[\]{};':"\\|.<>\/?]+$/.test(value)) {
      return;
    } else {
      setAdress(value);
      setCreateEstateAddressError(false);
    }
  };

  async function adressHandler(value) {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    setAdress(value);
    setCoordinates(latLng);
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Skapa ny Fastighet</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%" }}
        >
          <FormControl>
            <InputLabel>Namn</InputLabel>
            <Input
              value={estateName}
              error={createEstateNameError}
              onChange={e => {
                estateNameHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field1}</InputLabel>
            <Input
              value={estateId}
              error={createEstateIdError}
              onChange={e => {
                estateIdHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field2}</InputLabel>
            <Input
              value={writerObj}
              onChange={e => {
                setWriter(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field3}</InputLabel>
            <Input
              value={technical}
              onChange={e => {
                setTechnical(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>{company.field4}</InputLabel>
            <Input
              value={other}
              onChange={e => {
                setOther(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <PlaceSelect
              address={address}
              adressHandler={adressHandler}
              adressStateHandler={adressStateHandler}
              addressError={createEstateAddressError}
            ></PlaceSelect>
          </FormControl>
          
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          onClick={createFolder}
          color="primary"
          variant="contained"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFolderDialog2;

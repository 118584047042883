import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCompany } from "../../Services/Companies/companiesHooks";
import EditCompanyDialog from "./EditCompanyDialog";
import AddUserCompanyDialog from "./AddUserCompanyDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem
} from "@material-ui/core";
import { PutUserService } from "../../Services/Users/userServices";
import { useAuth0 } from "./../../react-auth0-spa";

const useStyles = makeStyles({
  root: {
    width: "100%",
    miNHeight: "350px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  button: {
    margin: "20px auto"
  }
});

const EditUser = ({ companyId, user, userDetails, callbackEditRole }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [role, setRole] = useState(user.userRole);
  const {
    getTokenSilently,
    
  } = useAuth0();
  let usr = useAuth0().user
  const roleHandler = e => {
    setRole(e.target.value);
  };

  const editModeHandler = () => {
    setEditMode(!editMode);
  };

  const confirmEditHandler = () => {
    (async () => {
      let token = await getTokenSilently()

      const resp = await PutUserService(companyId, user.userId, role, token);
      setRole("");
      callbackEditRole({ id: user.userId, role: role });
    })();
    setEditMode(!editMode);
  };

  return (
    <ListItem key={user.id} style={{ display: "flex" }}>
      <ListItemText>{userDetails[0].name}</ListItemText>
      {!editMode ? (
        <ListItemText>{user.userRole}</ListItemText>
      ) : (
        <>
          <Select
            value={role}
            onChange={e => roleHandler(e)}
            displayEmpty
            className={classes.selectEmpty}
          >
            <MenuItem value="" disabled>
              {role}
            </MenuItem>

            <MenuItem value={"USER"}>User</MenuItem>
            <MenuItem value={"ADMIN"}>Admin</MenuItem>
          </Select>
        </>
      )}
      {!editMode ? (
        <Button
          style={{ backgroundColor: "#b2dfdb" }}
          onClick={editModeHandler}
        >
          Redigera
        </Button>
      ) : (
        <Button
          style={{ backgroundColor: "#76ff03" }}
          onClick={confirmEditHandler}
        >
          CONFIRM
        </Button>
      )}
    </ListItem>
  );
};

export default EditUser;

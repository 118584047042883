import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EditCompanyService } from "../../Services/Companies/companiesServices";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "550px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const EditCompanyDialog = ({
  companyData,
  editCompany,
  onClose,
  callbackEdit
}) => {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState(companyData.name || "");
  const [companyOrg, setCompanyOrg] = useState(companyData.orgNumber || "");
  const [loading, setLoading] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyOrgError, setCompanyOrgError] = useState(false);
  const {companyID} = useParams();
  const companyId = Number(companyID);

  useEffect(() => {
    if (companyName === "") {
      setCompanyNameError(true);
    }
    if (companyOrg === "") {
      setCompanyOrgError(true);
    }
  }, [companyName, companyOrg]);

  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  const redactComapany = async () => {
    setLoading(true);
    let company = companyData;
    company.name = companyName;
    company.orgNumber = companyOrg;
    company.updated = new Date();

    const resp = await EditCompanyService(companyId, company);
    callbackEdit(resp);
    setLoading(false);
    onClose();
  };

  const companyNameHandler = value => {
    if (/[\\|\/]+$/.test(value)) {
      return;
    } else {
      setCompanyName(value);
      setCompanyNameError(false);
    }
  };

  const companyOrgHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      return;
    } else {
      setCompanyOrg(value);
      setCompanyOrgError(false);
    }
  };

  return (
    <Dialog
      open={editCompany}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Redigera organisation</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%" }}
        >
          <FormControl>
            <InputLabel>Namn</InputLabel>
            <Input
              value={companyName}
              error={companyNameError}
              onChange={e => {
                companyNameHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Orgnummer</InputLabel>
            <Input
              value={companyOrg}
              onChange={e => {
                companyOrgHandler(e.target.value);
              }}
            />
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          onClick={redactComapany}
          color="primary"
          variant="contained"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCompanyDialog;

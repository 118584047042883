import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetCompany } from "../../Services/Companies/companiesHooks";
import EditCompanyDialog from "./EditCompanyDialog";
import AddUserCompanyDialog from "./AddUserCompanyDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem
} from "@material-ui/core";
import EditUser from "./EditUser";
import { useGetUsersDetails } from "../../Services/Users/userHooks";

const useStyles = makeStyles({
  root: {
    width: "100%",
    miNHeight: "350px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  button: {
    margin: "20px auto"
  }
});

const CompaniesPage = () => {
  const classes = useStyles();
  let { id } = useParams();
  const [compEdit, setCompEdit] = useState("");
  const [compEditRole, setCompEditRole] = useState("");
  const [compAdd, setCompAdd] = useState();
  const [companyData, allCompaniesLoading] = useGetCompany(
    id,
    compEdit,
    compEditRole,
    compAdd
  );
  const [
    usersDetailsData,
    usersDetailsLoading,
    usersDetailsError
  ] = useGetUsersDetails(id);
  //get other company stuff
  const [editCompany, setEditCompany] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [role, setRole] = useState("");

  const roleHandler = e => {
    // setRole(e.target.value);
  };

  const editModeHandler = () => {
    setEditMode(!editMode);
  };

  const callbackEdit = useCallback(value => {
    setCompEdit(value);
  }, []);

  const callbackEditRole = useCallback(value => {
    setCompEditRole(value);
  }, []);

  const callbackAdd = useCallback(value => {
    setCompAdd(value);
  }, []);

  return (
    <>
      {/* {!companyData || allCompaniesLoading ? null : ( */}
      <section>
        {!companyData ? null : (
          <EditCompanyDialog
            companyData={companyData}
            editCompany={editCompany}
            onClose={() => {
              setEditCompany(false);
            }}
            // path={folderPath}
            callbackEdit={callbackEdit}
          />
        )}

        <AddUserCompanyDialog
          addUser={addUser}
          onClose={() => {
            setAddUser(false);
          }}
          id={id}
          callbackAdd={callbackAdd}
        ></AddUserCompanyDialog>

        <header style={{ marginBottom: 30 }}>
          <Grid justify={"space-between"} container>
            <Grid item>
              {!companyData ? null : (
                <>
                  <Typography
                    style={{ marginBottom: 20, marginTop: 20 }}
                    variant="h4"
                    component="h1"
                  >
                    {companyData.name}
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20, marginTop: 20 }}
                    variant="h5"
                    component="h2"
                  >
                    {companyData.orgNumber}
                  </Typography>
                </>
              )}
            </Grid>
            <div>
              <Grid container justify="center" spacing={3}>
                <Grid item>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setAddUser(true);
                    }}
                  >
                    Lägg till Användare
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setEditCompany(true);
                    }}
                  >
                    Redigera
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </header>

        <Divider style={{ marginTop: 22, marginBottom: 8 }} />

        <List>
          <Typography
            style={{ marginBottom: 20, marginTop: 20 }}
            variant="h6"
            component="h4"
          >
            Users:
          </Typography>
          {companyData && !allCompaniesLoading && usersDetailsData
            ? companyData.users.map(user => {
                const userDetails = usersDetailsData.filter(
                  detail => user.userId === detail.user_id
                );
                return (
                  <EditUser
                    key={user.id}
                    companyId={id}
                    user={user}
                    userDetails={userDetails}
                    callbackEditRole={callbackEditRole}
                  />
                );
              })
            : null}
        </List>
      </section>
      {/* )} */}
    </>
  );
};

export default CompaniesPage;

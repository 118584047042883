import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { useSelector, useDispatch } from "react-redux";
import { setCompany } from "../../../Redux/actions/companyActions";
import { removeEstates } from "../../../Redux/actions/estatesActions";
import { useParams } from "react-router-dom";

const CompanyMenu = () => {
  const { companyID } = useParams();
  const companiesInRedux = useSelector(state => state.companies.companies);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const hist = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = c => {
    switchCompany(c);
    setAnchorEl(null);
  };

  const switchCompany = c => {
    // console.log("byt till det här companyt:", c);
    dispatch(removeEstates());
    hist.push(`/${c.id}`);
  };
  // console.log(companiesInRedux);
  return (
    <>
      <div
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ marginLeft: 10, marginRight: "auto" }}
      >
        {/* <BusinessCenterIcon /> */}
        {/* <List>
          <ListItem key={"Företag"}>
            <ListItemIcon>{<BusinessCenterIcon />}</ListItemIcon>
            <ListItemText primary={"Företag"} />
          </ListItem>
        </List> */}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {companiesInRedux
          ? companiesInRedux.map(company => {
              // console.log(company);
              return (
                <MenuItem
                  key={company.id}
                  onClick={() => {
                    handleClose(company);
                  }}
                >
                  {company.name}
                </MenuItem>
              );
            })
          : null}
      </Menu>
    </>
  );
};

export default CompanyMenu;

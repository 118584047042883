import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import Breadcrumbs from "../../components/Navigation/Breadcrumbs";
import CreateEstateDialog from "./CreateEstateDialog";
import { useParams } from "react-router-dom";

export const EstateListHeader = ({ callbackUl, selC, callbackCreate }) => {
  const [open, setOpen] = useState(false);
  const companies = useSelector(state => state.companies.companies);
  const user = useSelector(state => state.user.user)
  const { companyID } = useParams();
  const companiesFiltered = companies.filter(company => company.id === Number(companyID))[0]
  const role = companiesFiltered ? companiesFiltered.users.filter((userObj) => userObj.userId === user.sub)[0].userRole : null
  const isAdmin =  role === "ADMIN" || role === "SUPER_ADMIN"

  useSelector(state => {
  })
  return (
    <header>
      <Breadcrumbs
        crumbs={[
          {
            text: companiesFiltered.name,
            to: "/estates"
          }
        ]}
      />
      <Grid justify={"space-between"} alignItems={"center"} container>
        <Grid item>
          <Typography
            style={{ marginBottom: 20, marginTop: 20 }}
            variant="h4"
            component="h1"
          >
            {companiesFiltered.name}
          </Typography>
        </Grid>
        <div>
          <Grid container spacing={1}>
            <Grid item>
              {
                isAdmin ?  (
                  <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Skapa en ny fastighet
                  </Button>
                  <CreateEstateDialog
                    open={open}
                    company={companiesFiltered}
                    onClose={() => {
                      setOpen(false);
                    }}
                    callbackUl={callbackUl}
                    selC={selC}
                  />
                </>
                ) : null
              }
             
            </Grid>
          </Grid>
        </div>
      </Grid>
    </header>
  );
};

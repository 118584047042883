import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCompany } from "../../../Services/Companies/companiesHooks";
import EditCompanyDialog from "./EditCompanyDialog";
import AddUserCompanyDialog from "./AddUserCompanyDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem
} from "@material-ui/core";
import { PutUserService } from "../../../Services/Users/userServices";
import { useAuth0 } from "../../../react-auth0-spa";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    width: "100%",
    miNHeight: "350px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  button: {
    width: "7rem"
  }
});

const EditUser = ({
  companyId,
  user,
  userDetails,
  callbackEditRole,
  allCompaniesLoading,
  usersDetailsLoading,
  editButtonHandler,
  editButton,
  deleteButtonHandler,
  deleteModeHandler
}) => {
  const {
    getTokenSilently
  } = useAuth0();
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [role, setRole] = useState(user.userRole);
  const isAdmin = true

  const roleHandler = e => {
    setRole(e.target.value);
  };

  const editModeHandler = () => {
    setEditMode(!editMode);
    editButtonHandler();
  };

  const confirmEditHandler = () => {
    editButtonHandler();
    (async () => {
      let token = await getTokenSilently();
      const resp = await PutUserService(companyId, user.userId, role, token);
      setRole("");
      callbackEditRole({ id: user.userId, role: role });
    })();
    setEditMode(!editMode);
  };

  const confirmDeleteHandler = () => {
    editButtonHandler();
    (async () => {
      let token = await getTokenSilently();
      // const resp = await PutUserService(companyId, user.userId, role, token);
      // setRole("");
      // callbackEditRole({ id: user.userId, role: role });
    })();
    setDeleteMode(!deleteMode);
  };

  console.log("this is user details", userDetails)
  return (
    <ListItem key={user.id} style={{ display: "flex" }}>
      {allCompaniesLoading || usersDetailsLoading ? null : (
        <ListItemText style={{ flex: "1 1 auto" }}>
          {typeof userDetails[0] === "undefined"
            ? "Loading name..."
            : userDetails[0].name}

            {
              userDetails && userDetails[0] && !userDetails[0].last_login && (<i style={{fontSize:14, marginLeft:5}}>Väntar på att inbjudan accepteras.</i>)
            }
        </ListItemText>
      )}
      {!editMode ? (
        <ListItemText
          style={{ flex: "1 1 auto", textAlign: "right", marginRight: "25rem" }}
        >
          {user.userRole === "SUPER_ADMIN" ? "OWNER" : user.userRole}
        </ListItemText>
      ) : (
        <>
          <Select
            value={role}
            onChange={e => roleHandler(e)}
            displayEmpty
            className={classes.selectEmpty}
            style={{ marginRight: "25rem" }}
          >
            <MenuItem value="" disabled>
              {role}
            </MenuItem>
            <MenuItem value={"USER"}>User</MenuItem>
            <MenuItem value={"ADMIN"}>Admin</MenuItem>
          </Select>
        </>
      )}
      
      {!isAdmin  ? null : (
        <>
          {!editMode ? (
            <Button
              className={classes.button}
              style={{ backgroundColor: "#b2dfdb" }}
              onClick={editModeHandler}
              disabled={editButton || role === "SUPER_ADMIN"}
            >
              Redigera
            </Button>
          ) : (
            <Button
              className={classes.button}
              style={{ backgroundColor: "#76ff03" }}
              onClick={confirmEditHandler}

            >
              Bekräfta
            </Button>
          )}
          {!deleteMode ? (
            <Button
              className={classes.button}
              color="secondary"
              onClick={() => deleteModeHandler(user)}
              disabled={editButton || role === "SUPER_ADMIN"}
            >
              Ta bort
            </Button>
          ) : (
            <Button
              className={classes.button}
              // color="secondary"
              style={{ backgroundColor: "#303030" }}
              onClick={confirmDeleteHandler}
            >
              Bekräfta
            </Button>
          )}
        </>
      )}
    </ListItem>
  );
};

export default EditUser;

import React, { useEffect, useCallback } from "react";
import { useGetEstates } from "../../Services/Estates/estateHooks";
import { Container, Grid } from "@material-ui/core";
import Map from "./Map";
import Geocode from "react-geocode";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Card } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ListIcon from "@material-ui/icons/List";
import RoomIcon from "@material-ui/icons/Room";
import EstateCard from "./EstateCard";
import { EstateListHeader } from "./EstateListHeader";
import EstateList from "./EstateList";
import { useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { get } from "../../Services/restTemplate";
import { GetEstatesMarkers } from "../../Services/Estates/estateServices";
import SearchBox from "../../components/SearchBox/SearchBox";
import EstateMapList from "./EstateMapList";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

const EstateListPage = () => {

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const { companyID } = useParams();
  const currentEstateState = useSelector(state => state.estates);
  const selectedCompany = useSelector(state => state.companies.companies);
  const [estateCreated, setEstateCreated] = useState("");

  const selC = Number(companyID);

  const [currentPage, setCurrentPage] = useState(0);
  const [view, setView] = useState(largeScreen ? "map" : "list");
  const [singleMarker, setSingleMarker] = useState(false);
  const [estates, loadedPages, estateLoading, estateListError] = useGetEstates(
    currentPage,
    selC,
    10
  );

  const [currentEstates, setCurrentEstates] = useState();
  const [lat, setLang] = useState(59.3331369);
  const [long, setLong] = useState(18.087286);
  const [markers, setMarkers] = useState([]);
  const [points, setPoints] = useState([{ lat: 59.3331369, lng: 18.087286 }, {lat:59,lng:18}]);
  const [searchInput, setSearchInput] = useState("");
  
  const [selectedEstate, setSelectedEstate] = useState(null);

  useEffect(() => {
    setView(largeScreen ? "map" : "list")
  }, [largeScreen])

  useEffect(() => {
    Geocode.setApiKey("AIzaSyDL94a2o87vZYVP7-h8q9pmcT1IxuGQTFc");

    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          const resp = await GetEstatesMarkers(selC, token);


          setMarkers(resp.filter((res) => res.latitude && res.address !== ''));
          setSingleMarker(resp.length == 1);
          

          if(resp && resp.length < 5){
           // setView("map")
          }
        }
      }
    })();
  }, [selC]);



  const handleChange = (event, value) => {
    setCurrentPage(value);
    getNumberOfPage(value);
  };

  const callbackUl = useCallback(char => {
    setCurrentEstates(state => [char, ...state]);
  }, []);

  useEffect(() => {
    if (estates) {
      setCurrentEstates(estates);
    }
  }, [estates, selC]);

  const callbackCreate = useCallback(value => {
    setEstateCreated(value);
  }, []);

  const getNumberOfPage = page => {
    setCurrentPage(page - 1);
  };

  const getSearchInput = e => {
    setSearchInput(e.target.value);
  };

  const handleSubmit = e => {
    let searchItem = currentEstateState.estates.filter(
      item => item.name === searchInput
    );
    setCurrentEstates(searchItem);
    e.preventDefault();

    if (searchInput === "") {
      return;
    }
    setSearchInput("");
  };

  const handleAlignment = (event, newView) => {
    setView(newView);
  };

  const selectEstate = estate => {
    setSelectedEstate(estate);
  };

  return (
    <Container>
      <EstateListHeader
        callbackUl={callbackUl}
        selC={selC}
        callbackCreate={callbackCreate}
      />
      {
        largeScreen && (
        <Grid container justify="center" spacing={2}>
          <Grid>
            <div style={{ paddingBottom: "20px" }}>
              <ToggleButtonGroup
                exclusive
                aria-label="text alignment"
                onChange={handleAlignment}
              >
                <ToggleButton value="map" aria-label="centered">
                  <RoomIcon />
                </ToggleButton>
                <ToggleButton value="list" aria-label="left aligned">
                  <ListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
        </Grid>
        )
      }
      {view === "map" ? (
        <Grid>
          <Card
            style={{
              height: "auto",
              minWidth: "100%",
              position: "relative",
              display: "flex"
            }}
          >
            <div
              style={{
                height: 730,
                width: "20%",
                minWidth: 278,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <EstateMapList
                companyID={companyID}
                currentPage={currentPage}
                loadedData={currentEstates}
                loadedPages={loadedPages}
                loading={estateLoading}
                getNumberOfPage={getNumberOfPage}
                searchInput={searchInput}
                getSearchInput={getSearchInput}
                handleSubmit={handleSubmit}
              ></EstateMapList>
              <Pagination
                count={loadedPages}
                page={currentPage + 1}
                onChange={handleChange}
                style={{ marginTop: "auto", marginBottom: 10 }}
                siblingCount={0} 
                boundaryCount={1}
              />
            </div>
            <div style={{ height: 730, width: "80%", position: "relative" }}>
              {
                (!singleMarker) && (
                  <Map
                  lat={lat}
                  long={long}
                  markers={markers}
                  selectEstate={selectEstate}
                  singleMarker={markers.length > 1 ? false : true}
                />
                )
              }
              {
                singleMarker && (
                  <Map
                  lat={lat}
                  long={long}
                  markers={markers}
                  selectEstate={selectEstate}
                  singleMarker={true}
                />
                )
              }
             
            </div>
          </Card>
          {selectedEstate && (
            <EstateCard
              selectEstate={selectEstate}
              points={points}
              estate={selectedEstate}
              map="noMap"
              company={selectedCompany}
            />
          )}
        </Grid>
      ) : view === "list" ? (
        <>
          <Grid container justify="flex-start">
            <div style={{ paddingBottom: "20px", paddingLeft: "15px" }}>
              <SearchBox companyID={companyID}></SearchBox>
            </div>
          </Grid>

          <Grid container spacing={2}>
            <EstateList
              company={selectedCompany}
              companyID={companyID}
              currentPage={currentPage}
              loadedData={currentEstates}
              loadedPages={loadedPages}
              loading={estateLoading}
              getNumberOfPage={getNumberOfPage}
              searchInput={searchInput}
              getSearchInput={getSearchInput}
              handleSubmit={handleSubmit}
            />
          </Grid>
          <div style={{ paddingTop: "30px" }}>
            <Grid container spacing={2} justify="center">
              <Pagination
                count={loadedPages}
                page={currentPage + 1}
                onChange={handleChange}
                siblingCount={1} 
                boundaryCount={1}
              />
            </Grid>
          </div>
        </>
      ) : null}
    </Container>
  );
};

export default EstateListPage;

import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import EstateListPage from "./Pages/EstateListPage/EstateListPage";
import EstatePage from "./Pages/EstatePage/EstatePage";
import Header from "./components/Global/Header/header";
import UsersListPage from "./Pages/UsersListPage/UsersListPage";
import UserPage from "./Pages/UserPage/UserPage";
import SupportPage from "./Pages/SupportPage/SupportPage";
import CompaniesListPage from "./Pages/CompaniesListPage/CompaniesListPage";
import CompaniesPage from "./Pages/CompaniesPage/CompaniesPage";
import Company from "./Pages/AdminPage/Company/Company";
import { useSelector } from "react-redux";
import SelectCompany from "./Pages/SelectCompany/index";
import CompanySettingsPage from "./Pages/CompanySettingsPage/CompanySettingsPage";
import AdminPage from "./Pages/AdminPage/AdminPage";
import PDFViewerPage from "./Pages/PdfViewerPage/PdfViewerPage";

const Router = () => {
  const [chosenPdf, setChosenPdf] = useState("");

  const pdfHandler = file => {
    setChosenPdf(file);
  };

  const companiesRedux = useSelector(state => state.companies.companies);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <SelectCompany />
        </Route>
        {companiesRedux.length <= 0 ? null : (
          <>
            <Route exact path="/:companyID([0-9]+)">
              <Header>
                <EstateListPage />
              </Header>
            </Route>
            <Route exact path="/:companyID([0-9]+)/estates">
              <Header>
                <EstateListPage />
              </Header>
            </Route>
            <Route strict path="/:companyID([0-9]+)/estatespdf/:path+">

                <PDFViewerPage />
            </Route>
            <Route strict path="/:companyID([0-9]+)/estates/:id/:folderPath+">
              <Header>
                <EstatePage pdfHandler={pdfHandler} />
              </Header>
            </Route>
            <Route strict path="/viewer">
              <Header>
                <PDFViewerPage chosenPdf={chosenPdf} />
              </Header>
            </Route>
            <Route exact path="/:companyID([0-9]+)/settings">
              <Header>
                <CompanySettingsPage />
              </Header>
            </Route>
            <Route exact path="/companies">
              <Header>
                <CompaniesListPage />
              </Header>
            </Route>
            <Route strict path="/admin/companies/:id/">
              <Header>
                <Company />
              </Header>
            </Route>
            <Route exact path="/users">
              <Header>
                <UsersListPage />
              </Header>
            </Route>
            <Route exact path="/user">
              <Header>
                <UserPage />
              </Header>
            </Route>
            <Route exact path="/admin/:id">
              <Header>
                <AdminPage />
              </Header>
            </Route>
            <Route exact path="/support/:id">
              <Header>
                <SupportPage />
              </Header>
            </Route>
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

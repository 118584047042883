export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const CHANGE_COMPANY = "CHANGE_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_ESTATES = "GET_ESTATES";
export const ADD_ESTATE = "ADD_ESTATE";
export const REMOVE_ESTATES = "REMOVE_ESTATES";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_ADMIN = "GET_ADMIN";

import { useState, useEffect } from "react";
import {
  GetAllCompaniesService,
  GetCompaniesService
} from "../Companies/companiesServices";
import { useSelector, useDispatch } from "react-redux";
import { setCompanies } from "../../Redux/actions/companyActions";
import { useAuth0 } from "../../react-auth0-spa";

export const useGetAllCompanies = (page, companyId, createResp) => {
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();

  const [allCompanies, setAllCompanies] = useState(null);
  const [allCompaniesLoading, setAllCompaniesLoading] = useState(false);
  const [allCompaniesError, setAllCompaniesError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          setAllCompaniesError(true);
          const resp = await GetAllCompaniesService(token);
          if (!resp) {
            setAllCompaniesError(resp);
            setAllCompaniesLoading(false);
            return;
          }
          setAllCompaniesLoading(false);
          setAllCompaniesError(null);
          setAllCompanies(resp);
          dispatch(setCompanies(resp));
        }
      }
    })();
  }, [page, dispatch, companyId, createResp]);

  return [allCompanies, allCompaniesLoading, allCompaniesError];
};

export const useGetCompanies = (page, companyId, createResp) => {
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const [allCompanies, setAllCompanies] = useState(null);
  const [allPages, setAllPages] = useState(null);
  const [allCompaniesLoading, setAllCompaniesLoading] = useState(false);
  const [allCompaniesError, setAllCompaniesError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          setAllCompaniesError(true);
          const resp = await GetCompaniesService(page, companyId, token);
          if (!resp) {
            setAllCompaniesError(resp);
            setAllCompaniesLoading(false);
            return;
          }
          setAllCompaniesLoading(false);
          setAllCompaniesError(null);
          setAllPages(resp.totalPages);
          setAllCompanies(resp);
          dispatch(setCompanies(resp));
        }
      }
    })();
  }, [page, dispatch, companyId, createResp]);

  return [allCompanies, allPages, allCompaniesLoading, allCompaniesError];
};

export const useGetCompany = (id, edit, role, add, remove) => {
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const [company, setCompany] = useState(null);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyError, setCompanyError] = useState();

  const companies = new Map(
    useSelector(state => state.companies.companies).map(i => [i.id, i])
  );
  const maybeCompany = companies.get(Number(id));

  useEffect(() => {
    if (maybeCompany) {
      setCompany(maybeCompany);
    }
  }, [maybeCompany, edit]);

  useEffect(() => {
    (async () => {
      if (
        maybeCompany &&
        typeof add === "undefined" &&
        typeof role === "undefined"
      ) {

        setCompany(maybeCompany);
        return;
      }
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          setCompanyLoading(true);
          const resp = await GetCompaniesService(0, id, token);
          if (!resp) {
            setCompanyError(resp);
            setCompanyLoading(false);
          }

          let filterData = resp.filter(data => data.id === parseInt(id));
          setCompany(...filterData);
          setCompanyError(null);
          setCompanyLoading(false);
        }
      }
    })();
  }, [id, maybeCompany, edit, role, add, remove]);

  return [company, companyLoading, companyError];
};

import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { Input, InputLabel } from "@material-ui/core";
import "./PlaceSelect.css";

const PlaceSelect = ({ address, adressHandler, adressStateHandler, addressError}) => {
  return (
    <>
      <InputLabel>Adress</InputLabel>
      <PlacesAutocomplete
        value={address}
        onChange={adressStateHandler}
        onSelect={adressHandler}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Input {...getInputProps({ placeholder: "Type address" })} error={addressError} />​
            {loading ? <div>...loading</div> : null}​
            {(!suggestions || suggestions.length === 0) ? null : (
              <ul className="dropdown__search">
                {" "}
                {suggestions.map(suggestion => {
                  const style = {
                    backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                  };
                  return (
                    <li
                      className="dropdown__item__search elementToFadeIn"
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default PlaceSelect;

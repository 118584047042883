import { get, post } from "../restTemplate";

const base = "s3?companyId=";
const uploadFile = "/s3/uploadtoken?companyId=";
const downloadFile = "s3/token?companyId=";
const deleteFile = "/s3/delete?companyId=";
const getParts = "/s3/pspdf?";


export const GetFolderService = (path, companyId, token, page = 0) => {
  
  return post(`/${base}${companyId}&page=${page}`, {path: path.split("/").map((x) => decodeURIComponent(x)).join("/") + "/"}, token);
};

export const PostFolderService = body => {
  return post(`/${base}`, body);
};

export const GetPartsOfFile = (start, end, url, token) => {
  return get(getParts + `start=${start}&end=${end}&url=${encodeURIComponent(url)}`, token);
}

export const UploadFileService = (folderPath, filePath, companyId, token) => {
  let trueFile = filePath;
  if (trueFile.startsWith("/")) {
    let newFile = trueFile.substr(1);
    trueFile = newFile;
  }
  trueFile = decodeURIComponent(trueFile)
  let splittedTrueFile = trueFile.split("/")
  splittedTrueFile = splittedTrueFile.map((x,i) => {
    if(i !== splittedTrueFile.length -1){
      return x.replace(/_/g, " ")
    }
    return x
  })

  trueFile = splittedTrueFile.join("/")
  console.log("truefile", trueFile)

  return post(`${uploadFile}${companyId}&path=${folderPath}/${trueFile}`,{path:`${decodeURIComponent(folderPath)}/${trueFile}`}, token);
};

export const DownloadAll = (path, paths, companyId, token) => {
  let obj = {
    path: path,
    paths:paths
  }
  return post(`/s3/downloadall?companyId=${companyId}`, obj, token);
}

export const ChangeFolderNameService = (foldername, companyId, newName, token) => {
  let obj = {
    newName: decodeURIComponent(newName),
    path:decodeURIComponent(foldername)
  }
  return post(`/s3/changename?companyId=${companyId}`, obj, token);
}

export const CopyFolderService = (folderPath, companyId, foldername,estate, token) => {
  let obj = {
    path:folderPath,
    newName: foldername,
    estate:estate
  }
  return post(`/s3/copy?companyId=${companyId}`,obj, token);
}

export const ReadPDFService = (folderPath, companyId, token) => {
  let p = folderPath.split("/")
  let fileName = p.pop()
  p = p.join("/")
  return post(`/s3/readpdf?companyId=${companyId}`,{path: p.split("/").map((x) => decodeURIComponent(x)).join("/") + "/" + fileName}, token);
}

export const DeleteFileService = (companyId, filePath, token) => {
  let obj = {
    file:filePath
  }
  return post(`${deleteFile}${companyId}`,obj, token);
};

export const OpenFileService = (companyId, filePath, token) => {
  console.log("PATH", filePath)
  return post(`/${downloadFile}${companyId}`,{file:filePath}, token);
};

import React from 'react';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { get } from '../../Services/restTemplate';
import { useState } from 'react';

const CompanySettingsPage = () => {

    const {companyID} = useParams()
    const currentID = Number(companyID);
    const companies = useSelector((state) => state.companies.companies);



    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setUsersLoading(true);
            const resp = await get(`/user/userscompany?companyId=${companyID}`);
            setUsers(resp.items);
            setUsersLoading(false);
        })()
    }, [companyID])

  

    if(companies.length === 0) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    const currentCompany = companies.find((c) => c.id === currentID);

    return (
        <Container>
            {
                currentCompany.name
            }
            {
                !usersLoading && (
                    <div>
                        {
                            users.map(u => {
                                return (
                                    <p>
                                        {u.name}
                                    </p>
                                )
                            })
                        }
                    </div>
                )
            }
            
        </Container>
    )
}

export default CompanySettingsPage;
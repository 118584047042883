import React from "react";
import { Container, Grid, Paper, Typography, Link } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCompany } from "../../Redux/actions/companyActions";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      margin: "8px 0"
    },
    link: {
      display: "block",
      textAlign: "center",
      padding: 8,
      border: "2px solid #f2f2f2",
      background: "#f8f8f8"
    }
  })
);

const SelectableCompany = ({ id, name }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <li className={classes.container}>
      <Link
        onClick={() => dispatch(setCompany(id))}
        color="textPrimary"
        underline="none"
        className={classes.link}
        to={`/${id}`}
        component={RouterLink}
      >
        {name}
      </Link>
    </li>
  );
};

export default SelectableCompany;

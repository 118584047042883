import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Input, InputLabel, FormControl, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { useAuth0 } from "../../react-auth0-spa";
import { get } from "../../Services/restTemplate";
import { useHistory, useLocation } from "react-router-dom";

const SearchBox = ({ companyID }) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();
  const [searchState, setSearchState] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showSugg, setShowSugg] = useState(false);
  const [listSuggestions, setListSuggestions] = useState(null);
  const outside = useRef(null);
  const history = useHistory();

  const searchHandler = e => {
    setSearchState(e);
    (() => {
      if (searchState !== "") {
        setTimeout(() => {
          submitHandler(e);
        }, 500);
      }
    })();
  };

  const submitHandler = query => {
    (async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        if (token) {
          get(
            `/s/realestates?companyId=${companyID}&query=${query}`,
            token
          ).then(resp => {

            setSearchSuggestions(state => [...resp]);
          });
        }
      }
    })();
  };

  const closeDropDownHandler = e => {
    if (!outside.current.contains(e.target)) {
      setShowSugg(false);
    } else {
      setShowSugg(true);
    }
  };

  const selectItemHandler = e => {
    setSearchState("");
    setListSuggestions([]);
    setShowSugg(false);
    history.push(`/${companyID}/estates/${e.id}/${e.folderName}/`);
    console.log(e);
  };

  useEffect(() => {
    if (searchSuggestions.length >= 0) {
      setListSuggestions(searchSuggestions.splice(0, 5));
    }
  }, [searchSuggestions]);

  useEffect(() => {
    document.addEventListener("mousedown", closeDropDownHandler, false);
    return () => {
      document.removeEventListener("mousedown", closeDropDownHandler, false);
    };
  }, []);

  const pressEnterHandler = e => {
    if (e.key === "Enter") {
      history.push(
        `/${companyID}/estates/${listSuggestions[0].id}/${listSuggestions[0].folderName}/`
      );
    }
  };

  return (
    <div ref={outside}>
      <form
        onSubmit={e => e.preventDefault()}
        onKeyDown={e => pressEnterHandler(e)}
      >
        <FormControl>
          <InputLabel>Sök efter fastighet</InputLabel>
          <Input
            value={searchState}
            onChange={e => searchHandler(e.target.value)}
          ></Input>
        </FormControl>
        {(!showSugg || !listSuggestions || !listSuggestions.length > 0) ? null : (
          <ul
            className="dropdown"
            style={{ display: "block", position: "absolute" }}
          >
            {!listSuggestions
              ? null
              : listSuggestions.map(estate => {
                  return (
                    <li
                      key={estate.name}
                      className="dropdown__item elementToFadeIn"
                      onClick={() => selectItemHandler(estate)}
                    >
                      {estate.name}
                    </li>
                  );
                })}
          </ul>
        )}
      </form>
    </div>
  );
};

export default SearchBox;

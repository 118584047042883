import { GET_ADMIN } from "../actions/types";

const initialState = {
  admin: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN:
      return { ...state, admin: action.payload.admin };

    default:
      return state;
  }
}

import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import reducer from "./reducers/index";

const middleWare = [thunk];

const middleWareEnhancer = applyMiddleware(...middleWare);

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
  trace: true
});

const store = createStore(
  reducer,
  compose(composeEnhancers(middleWareEnhancer))
);

export default store;

import React from "react";
import MaterialCrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Link as Routerlink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";

const Breadcrumbs = ({ crumbs, hideBase }) => {
  // console.log(crumbs);

  const { companyID } = useParams();
  const handeFileLoading = () => {
    // console.log("file crumbing");
  };
  let getText = (text) => {
    try {return decodeURIComponent(text.replace(/_/g, " "))}catch(e){return text}
  }
  return (
    <MaterialCrumbs aria-label="breadcrumb">
      {!hideBase && (
        <Link color="inherit" to={`/${companyID}`} component={Routerlink}>
          TQI Dokumentportal
        </Link>
      )}
      {crumbs.map(({ to, text }, i) => {
        if (i === crumbs.length - 1) {
          
          return (
            <Typography key={`/${companyID}` + to} color="textPrimary">
              {getText(text)}
            </Typography>
          );
        }
        return (
          <Link
            key={`/${companyID}` + encodeURIComponent(to)}
            color="inherit"
            style={{color:"black"}}
            to={`/${companyID}` + to.split("/").map(x => encodeURIComponent(x)).join("/")}
            component={Routerlink}
          >
            {getText(text)}
            
          </Link>
        );
      })}
    </MaterialCrumbs>
  );
};

export default Breadcrumbs;

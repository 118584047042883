import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Map from "./Map";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import formatDate from "../../Functions/formatDate";

const useStyles = makeStyles({
  root: {}
});

const EstateCard = ({
  estate,
  hideActions,
  map,
  uploadInProgress,
  progressBarValue,
  selectEstate,
  company
}) => {
  const classes = useStyles();
  // console.log("estate", estate);
  const history = useHistory();
  const createdDate = new Date(estate.created);
  const formattedDate = formatDate(estate.created);
    

  const { companyID } = useParams();

  return (
    <Card className={classes.root}>
      {!hideActions ? (
        <CardActionArea>
          <Link
            component={RouterLink}
            to={`/${companyID}/estates/${estate.id}/${estate.folderName}/`}
            color="inherit"
            underline="none"
          >
            {map === "noMap" ? null : (
              <div style={{ height: 190, position: "relative" }}>
                {!uploadInProgress ? (
                  <Map lat={59.3331369} long={18.087286} markers={[estate]} singleMarker/>
                ) : null}
              </div>
            )}

            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {estate.name}
              </Typography>
              {estate.realEstateId && (
                <Typography gutterBottom variant="body2" component="p">
                  {company.field1}: {estate.realEstateId}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary" component="p">
                Skapad:
                {formattedDate}
              </Typography>
            </CardContent>
          </Link>
        </CardActionArea>
      ) : (
        <>
          <div style={{ height: 190, position: "relative" }}>
            {progressBarValue === 0 || progressBarValue === 100 ? (
              <Map
                lat={estate.lat}
                long={estate.lng}
                address={estate.address}
                estate={estate}
                markers={[estate]}
                selectEstate={selectEstate}
                points={null}
                singleMarker
              />
            ) : null}
          </div>

          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {estate.name}
            </Typography>
            {estate.realEstateId && (
              <Typography gutterBottom variant="body2" component="p">

                {company.field1}: {estate.realEstateId}
              </Typography>
            )}

            {estate.writer !== '' && (
              <Typography gutterBottom variant="body2" component="p">
                {company.field2}: {estate.writer}
              </Typography>
            )}
            {estate.technical !== '' && (
              <Typography gutterBottom variant="body2" component="p">
                {company.field3}: {estate.technical}
              </Typography>
            )}
            
            {estate.other !== '' && (
              <Typography gutterBottom variant="body2" component="p">
                {company.field4}: {estate.other}
              </Typography>
            )}
            {estate.address !== '' && (
              <Typography gutterBottom variant="body2" component="p" style={{marginTop:10}}>
                {estate.address}
              </Typography>
            )}
          </CardContent>
        </>
      )}

      {!hideActions && (
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(
                `/${companyID}/estates/${estate.id}/${estate.folderName}/`
              );
            }}
          >
            Visa
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default EstateCard;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EditEstateService } from "../../Services/Estates/estateServices";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { useHistory } from "react-router-dom";
import PlaceSelect from "../../components/Global/PlaceSelect/PlaceSelect";
import { DeleteEstateService } from "../../Services/Estates/estateServices";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "550px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },

  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const EditUserDialog = ({ open, onClose, path, callbackEdit, userData }) => {
  const classes = useStyles();
  const history = useHistory();
  //   const [estateName, setEstateName] = useState(estateData.name || "");
  //   const [estateId, setEstateId] = useState(estateData.realEstateId || "");
  const [loading, setLoading] = useState(false);
  const {companyID} = useParams();
  const companyId = Number(companyID);
  const [address, setAdress] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [deleteEstateDialog, setDeleteEstateDialog] = useState(false);
  const [deleteEstateName, setDeleteEstateName] = useState("");
  const [copyEstateDialog, setCopyEstateDialog] = useState(false);
  const [copyEstateName, setCopyEstateName] = useState("");
  const handleCopyDialogOpen = () => {
    setCopyEstateDialog(true);
  };

  //   const handleCopyDialogClose = () => {
  //     setCopyEstateDialog(false);
  //   };

  //   const copyEstateNameHandler = name => {
  //     setCopyEstateName(name);
  //   };

  //   const copyEstateConfirmation = () => {
  //     if (copyEstateName === estateName) {
  //     } else {
  //       setCopyEstateDialog(false);
  //     }
  //     history.push(`/estates/${companyId}/${copyEstateName}`);
  //   };

  //   const handleDeleteDialogOpen = () => {
  //     setDeleteEstateDialog(true);
  //   };

  //   const handleDeleteDialogClose = () => {
  //     setDeleteEstateDialog(false);
  //   };

  //   const deleteEstateNameHandler = name => {
  //     setDeleteEstateName(name);
  //   };

  //   const deleteEstateConfirmation = () => {
  //     if (deleteEstateName === estateName) {
  //       DeleteEstateService(estateData.id).then(resp => {
  //         history.push(`/estates/`);
  //       });
  //       console.log(`Delete ${estateName} with id ${estateData.id}`);
  //       setDeleteEstateDialog(false);
  //     }
  //   };

  useEffect(() => {
    setLoading(false);
  }, [open]);

  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  //   const createFolder = async () => {
  //     setLoading(true);
  //     const resp = await EditEstateService(companyId, {
  //       id: estateData.id,
  //       name: estateName,
  //       created: estateData.created,
  //       latitude: coordinates.lat,
  //       longitude: coordinates.lng,
  //       realEstateId: estateId,
  //       folderName: estateData.folderName,
  //       company: estateData.company
  //     });
  //     callbackEdit(resp);
  //     setLoading(false);
  //     onClose();
  //   };

  const estateNameHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      return;
    } else {
      //   setEstateName(value);
    }
  };
  const estateIdHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      return;
    } else {
      //   setEstateId(value);
    }
  };

  const adressStateHandler = value => {
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]+$/.test(value)) {
      return;
    } else {
      setAdress(value);
    }
  };

  async function adressHandler(value) {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    setAdress(value);
    setCoordinates(latLng);
  }
  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Redigera Fastighet</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%" }}
        >
          <FormControl>
            <InputLabel>Namn</InputLabel>
            <Input
              value={"namn"}
              onChange={e => {
                estateNameHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Fastighets id</InputLabel>
            <Input
              value={"id"}
              onChange={e => {
                estateIdHandler(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <PlaceSelect
              address={address}
              adressHandler={adressHandler}
              adressStateHandler={adressStateHandler}
            ></PlaceSelect>
          </FormControl>
        </FormControl>
        {/* <Button onClick={handleCopyDialogOpen}>Kopiera fastighet</Button> */}
        <Button onClick={() => console.log("button click")} color="secondary">
          TA BORT
        </Button>
      </DialogContent>
      <Dialog
        open={copyEstateDialog}
        onClose={() => console.log("button click")}
      >
        <InputLabel>Skriv in nytt namn på fastighet</InputLabel>
        <Input
          value={copyEstateName}
          onChange={e => {
            // copyEstateNameHandler(e.target.value);
          }}
        />
        <Button onClick={() => console.log("button click")}>Spara</Button>
      </Dialog>
      <Dialog
        open={deleteEstateDialog}
        onClose={() => console.log("button click")}
      >
        <p>Vill du verkligen ta bort denna fastigheten?</p>
        <InputLabel>Skriv in namnet på valda fastigheten</InputLabel>
        <Input
          value={deleteEstateName}
          onChange={e => {
            // deleteEstateNameHandler(e.target.value);
          }}
        />
        <Button onClick={() => console.log("button click")} color="secondary">
          TA BORT
        </Button>
      </Dialog>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          onClick={() => {}}
          color="primary"
          variant="contained"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;

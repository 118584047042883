import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetCompany } from "../../../Services/Companies/companiesHooks";
import EditCompanyDialog from "./EditCompanyDialog";
import AddUserCompanyDialog from "./AddUserCompanyDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import EditUser from "./EditUser";
import { useGetUsersDetails } from "../../../Services/Users/userHooks";
import Breadcrumbs from "../../../components/Navigation/Breadcrumbs/Breadcrumbs";
import { useHistory, useLocation } from "react-router-dom";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { useAuth0 } from "../../../react-auth0-spa";
import { useSelector, useDispatch } from "react-redux";
import { DeleteUserService } from "../../../Services/Users/userServices";
import { DeleteCompanyService } from "../../../Services/Companies/companiesServices";

import { setCompany, } from "../../../Redux/actions/companyActions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    miNHeight: "350px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  button: {
    margin: "20px auto"
  }
});

const Company = () => {
  const {
    authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    authUser,
    user,
    getTokenSilently
  } = useAuth0();
  const classes = useStyles();
  let { id } = useParams();
  const [compEdit, setCompEdit] = useState("");
  const [compEditRole, setCompEditRole] = useState("");
  const [compAdd, setCompAdd] = useState();
  const [compDelete, setCompDelete] = useState();
  const [companyData, allCompaniesLoading] = useGetCompany(
    id,
    compEdit,
    compEditRole,
    compAdd,
    compDelete
  );
  const [
    usersDetailsData,
    usersDetailsLoading,
    usersDetailsError
  ] = useGetUsersDetails(id, compEdit, compEditRole, compAdd, compDelete);
  //get other company stuff
  const [editCompany, setEditCompany] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [role, setRole] = useState("");
  const history = useHistory();
  const roles = companyData ? companyData.users.filter((userObj) => userObj.userId === user.sub)[0].userRole : null
  const isAdmin = roles === "SUPER_ADMIN"
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCompany(id));
  }, []);

  const roleHandler = e => {
    // setRole(e.target.value);
  };

  const editButtonHandler = () => {
    setEditButton(!editButton);
  };

  const deleteCompany = () => {
    (async () => {
      let token = await getTokenSilently();
      const resp =  DeleteCompanyService(id, token);

      window.location = '/'
    })();
    

    
  }

  const deleteButtonHandler = () => {
    setDeleteButton(!deleteButton);
  };

  const deleteModeHandler = e => {
    editButtonHandler();
    deleteButtonHandler();
    setDeleteUser(e);
  };

  const deleteUserhandler = () => {
    (async () => {
      let token = await getTokenSilently();
      const resp = await DeleteUserService(id, deleteUser.userId, token);
      setRole("");
      callbackRemove(deleteUser.id);
    })();
    editButtonHandler();
    deleteButtonHandler();
  };

  const abortDeleteHandler = () => {
    setDeleteUser(null);
    editButtonHandler();
    deleteButtonHandler();
  };

  const callbackEdit = useCallback(value => {
    setCompEdit(value);
  }, []);

  const callbackEditRole = useCallback(value => {
    setCompEditRole(value);
  }, []);

  const callbackAdd = useCallback(value => {
    setCompAdd(value);
  }, []);

  const callbackRemove = useCallback(value => {
    setCompDelete(value);
  });

  return (
    <>
      <Button
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowLeftIcon /> Tillbaka
      </Button>
      {/* {!companyData || allCompaniesLoading ? null : ( */}
      <section>
        {!companyData ? null : (
          <EditCompanyDialog
            companyData={companyData}
            editCompany={editCompany}
            onClose={() => {
              setEditCompany(false);
            }}
            // path={folderPath}
            callbackEdit={callbackEdit}
          />
        )}

        <AddUserCompanyDialog
          addUser={addUser}
          onClose={() => {
            setAddUser(false);
          }}
          id={id}
          callbackAdd={callbackAdd}
        ></AddUserCompanyDialog>

        <header style={{ marginBottom: 30 }}>
          <Grid justify={"space-between"} container>
            <Grid item>
              {!companyData ? null : (
                <>
                  <Typography
                    style={{ marginBottom: 20, marginTop: 20 }}
                    variant="h4"
                    component="h1"
                  >
                    {companyData.name}
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20, marginTop: 20 }}
                    variant="h5"
                    component="h2"
                  >
                    {companyData.orgNumber}
                  </Typography>
                </>
              )}
            </Grid>
            <div>
              <Grid container justify="center" spacing={1}>
                
                  
                    <>
                    <Grid item>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          setAddUser(true);
                        }}
                      >
                        Lägg till Användare
                      </Button>
                    </Grid>
                    <Grid item>
                       <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setEditCompany(true);
                        }}
                      >
                        Redigera
                      </Button>
                    </Grid>
                    {
                      isAdmin && (
                        <Grid item>
                       <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          deleteCompany(true);
                        }}
                      >
                        Ta bort organisation
                      </Button>
                    </Grid>
                      )
                    }
                    
                    </>
                
            </Grid>
            </div>
            </Grid>
        </header>

        <Divider style={{ marginTop: 22, marginBottom: 8 }} />

        <List>
          <Typography
            style={{ marginBottom: 20, marginTop: 20 }}
            variant="h6"
            component="h4"
          >
            Users:
          </Typography>
          <Dialog
            open={deleteButton}
            onClose={() => {
              setDeleteButton(false);
              setEditButton(false);
            }}
            PaperProps={{ classes: { root: classes.root } }}
          >
            <DialogTitle>Ta bort</DialogTitle>
            <DialogContent style={{ width: "100%" }}>
              <Typography>Vill du verkligen ta bort denna user?</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                // disabled={loading}
                onClick={deleteUserhandler}
                color="secondary"
                variant="text"
              >
                JA
              </Button>
              <Button
                // disabled={loading}
                onClick={abortDeleteHandler}
                color="primary"
                variant="contained"
              >
                NEJ
              </Button>
            </DialogActions>
          </Dialog>

          {companyData && !allCompaniesLoading && usersDetailsData
            ? companyData.users.map(user => {
                const userDetails = usersDetailsData.filter(
                  detail => user.userId === detail.user_id
                );
                return (
                  <EditUser
                    key={user.id}
                    companyId={id}
                    user={user}
                    userDetails={userDetails}
                    callbackEditRole={callbackEditRole}
                    usersDetailsLoading={usersDetailsLoading}
                    allCompaniesLoading={allCompaniesLoading}
                    editButtonHandler={editButtonHandler}
                    editButton={editButton}
                    deleteButtonHandler={deleteButtonHandler}
                    deleteButton={deleteButton}
                    deleteModeHandler={deleteModeHandler}
                  />
                );
              })
            : null}
        </List>
      </section>
      {/* )} */}
    </>
  );
};

export default Company;

import React, { useState } from "react";
import Router from "./Router";
import { Provider } from "react-redux";
import store from "../src/Redux/store";
import { useAuth0 } from "./react-auth0-spa";
import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { get, post } from "./Services/restTemplate";
import { useSelector, useDispatch } from "react-redux";
import { setCompanies } from "./Redux/actions/companyActions";
import { removeEstates } from "./Redux/actions/estatesActions";
import { setUser } from "./Redux/actions/userActions";
import { setAdmin } from "./Redux/actions/adminAction";
import { Grid, CircularProgress } from "@material-ui/core/";
import { useGetAllCompanies } from "./Services/Companies/companiesHooks";

const App = () => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently,
    getIdTokenClaims
  } = useAuth0();

  const dispatch = useDispatch();
  const [
    allCompanies,
    companiesPage,
    companiesLoading,
    companiesError
  ] = useGetAllCompanies(1, 1);

  useEffect(() => {
    if (!loading) {
      if (!isAuthenticated) {
        loginWithRedirect({});
      }
    }
  }, [loading, isAuthenticated]);
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  useEffect(() => {
    (async () => {

      if (isAuthenticated) {
        const token = await getTokenSilently();
        const idTokens = await getIdTokenClaims()
        if (token) {
          await sleep(1000)
          
          const resp = await get(`/realestate/allcompanies?name=${encodeURIComponent(idTokens.sub)}`, null);
          dispatch(setCompanies(resp));
          dispatch(removeEstates());
        }
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(setUser(user));
    dispatch(setAdmin(user && user["https://tqi.se/roles"] ? user["https://tqi.se/roles"].includes("admin") : false ));
  });

  if (loading) {
    return (
      <Grid
        container
        justify="center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "25%"
          }}
        >
          <h1>Loading...</h1>
          <CircularProgress />
        </div>
      </Grid>
    );
  }

  if (!isAuthenticated) {
    return (
      <div>
        <Button onClick={() => loginWithRedirect({})}>Logga in</Button>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};

export default App;

import { get, post, put, DELETE } from "../restTemplate";

const base = "user";
const users = "userscompany?companyId=";
const editUser = "usercompany?companyId=";

export const GetUsersService = token => {
  return get(`/${base}`, token);
};

export const GetUsersDetailsService = (companyId, token) => {
  return get(`/${base}/${users}${companyId}`, token);
};

export const GetUserService = id => {
  return get(`/${base}/${id}`);
};

export const PostUserService = (body, token) => {
  return post(`/${base}`, body, token);
};

export const DeleteUserService = (companyId, id, token) => {
  return DELETE(`/${base}/${editUser}${companyId}&userId=${id.replace("auth0|", "")}`, token);
};

export const PutUserService = (companyId, userId, newRole, token) => {
  const finalId = encodeURIComponent(userId);

  return put(
    `/${base}/${editUser}${companyId}&userId=${finalId}&newRole=${newRole}`,
    {},
    token
  );
};

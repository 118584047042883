import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  Input,
  TextField,
  MenuItem
} from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

const useStyles = makeStyles({
  root: {
    width: "25rem",
    minHeight: "550px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },

  content: {
    width: "100%"
  },
  form: {
    width: "85%"
  }
});

const userrole = [
  {
    value: "Användare"
  },
  {
    value: "Adminstratör"
  },
  {
    value: "Superadmin"
  }
];

const CreateUsersPage = ({ open, onClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Användarroll");
  // const companies = useSelector(state => state.companies.companies);
  // const companyId = companies[0].id;


  const nameHandler = value => {
    setName(value);
  };
  const emailHandler = value => {
    setEmail(value);
  };
  const rollHandler = event => {
    setRole(event.target.value);
  };

  useEffect(() => {
    setLoading(false);
  }, [open]);
  const handleCloseWrapper = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  const createUserHandler = value => {
    
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Skapa User</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText></DialogContentText>
        <FormControl
          fullwidth="true"
          disabled={loading}
          style={{ width: "85%" }}
        >
          <FormControl>
            <InputLabel>Användare</InputLabel>
            <Input
              value={name}
              onChange={e => {
                nameHandler(e.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <InputLabel>E-post</InputLabel>
            <Input
              value={email}
              onChange={e => {
                emailHandler(e.target.value);
              }}
            />
          </FormControl>

          <TextField
            id="standard-select-currency"
            select
            label="Användarroll"
            value={role}
            onChange={rollHandler}
            helperText=""
          >
            {userrole.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>

          <FormControl>
            {/* <PlaceSelect
                            address={address}
                            adressHandler={adressHandler}
                            adressStateHandler={adressStateHandler}
                        ></PlaceSelect> */}
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleCloseWrapper}
          color="secondary"
          variant="text"
        >
          Stäng
        </Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={() => createUserHandler()}
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUsersPage;

import { GET_ESTATES, ADD_ESTATE, REMOVE_ESTATES } from "./types";

export const setEstates = estates => ({
  type: GET_ESTATES,
  payload: { estates: estates }
});

export const addEstate = estate => ({
  type: ADD_ESTATE,
  payload: { estate }
});

export const removeEstates = () => ({
  type: REMOVE_ESTATES,
  payload: {}
});

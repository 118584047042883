import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { Grid, Button, Typography } from "@material-ui/core/";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useOpenFile } from "../../Services/Folders/folderHooks";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func
};

export default function FileModal({
  filePreview,
  chosenImg,
  fileType,
  fileViewHandler,
  currentFileIndex,
  fileVieNextHandler,
  fileViewPrevHandler,
  setPdf,
  pdfHandler,
  fileName,
  imgLoading,
  openFile,
  companyId
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(filePreview);
  // const [thisFile, thisFileLoading] = useOpenFile(companyId, openFile);
  const history = useHistory();
  const [iLoad, setILoad] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    fileViewHandler("");
    setOpen(false);
  };
  if(fileType.toLowerCase() === ".pdf")  setPdf({ url: chosenImg, name: fileName })  
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open} style={{ outline: "none" }}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button>
            <ArrowBackIosIcon
              style={{ color: "#ffffff" }}
              onClick={() => fileViewPrevHandler(currentFileIndex)}
            />
          </Button>
          <Grid
            container
            justify="center"
            spacing={2}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              itemsAlign: "center",
              textAlign: "center",
              minWidth: 450,
              maxWidth: 650
            }}
          >
            {fileType === ".jpg" || fileType === ".png" || fileType === ".jpeg" ? (
              <img
                alt="preview"
                src={chosenImg}
                style={{ width: "inherit", height: "auto" }}
              />
            ) : (
              // </Link>
              // </Link>
              <Typography style={{ color: "#ffffff" }}>{fileName}</Typography>
            )}
            <Button
              style={{ flex: 1, backgroundColor: "#efebe9" }}
              onClick={() => {
                fileType.toLowerCase() === ".pdf" ? setPdf({ url: chosenImg, name: fileName }) : window.open(`${chosenImg}`, `_blank`).focus();
              }}
            >
              Ladda ner <GetAppIcon />
            </Button>
          </Grid>
          <Button>
            <ArrowForwardIosIcon
              style={{ color: "#ffffff" }}
              onClick={() => fileVieNextHandler(currentFileIndex)}
            />
          </Button>
        </Grid>
      </Fade>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import formatDate from "../../Functions/formatDate";
import {
  makeStyles,
  Paper,
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between"
  },
  left: { marginLeft: "auto" },
  nested: {
    paddingLeft: theme.spacing(10)
  },
  table: {
    minWidth: "100%"
  },
  color: {
    backgroundColor: "#cccccc"
  }
}));

export default function Estates({
  company,
  companyID,
  loadedPages,
  loadedData,
  loading,
  getNumberOfPage,
  searchInput,
  getSearchInput,
  handleSubmit,
  currentPage
}) {
  const classes = useStyles();
  const [pages, setPages] = useState([]);
  const history = useHistory();
  
  const currentCompany = company.filter(x => x.id === Number(companyID))[0]
  console.log("current", currentCompany)
  useEffect(() => {
    const createPages = p => {
      for (let i = 0; i < p; i++) {
        setPages(state => [...state, i + 1]);
      }
    };
    createPages(loadedPages);
  }, [loadedPages]);

  
  const goToEstateHandler = (id, folderName) => {
    console.log("pressing")
    history.push(`/${companyID}/estates/${id}/${encodeURIComponent(folderName)}/`);
  };
  return (
    <>
      <Container>
        <Grid container justify="center">
          <div style={{ paddingBottom: "20px" }}>
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={e => {
                handleSubmit(e);
              }}
              style={{ display: "none" }}
            >
              <TextField
                id="standard-basic"
                label="Search"
                value={searchInput}
                onChange={e => {
                  getSearchInput(e);
                }}
              />
            </form>
          </div>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {loading ? (
            <h1>Loading...</h1>
          ) : loadedData && loadedData.length === 0 ? (
            <>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Du har inga fastigheter än!
                  </Typography>
                </CardContent>
              </Card>
            </>
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Namn</TableCell>
                    <TableCell align="right" style={{ textAlign: "center" }}>
                      {currentCompany.field1}
                    </TableCell>
                    <TableCell align="right">Datum&nbsp;(skapad)</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loadedData
                    ? loadedData.map(item => (
                        <TableRow
                          key={item.id}
                          onClick={() =>
                            goToEstateHandler(item.id, item.folderName)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell component="th" scope="row">
                            {item.name}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ textAlign: "center" }}
                          >
                            {item.realEstateId}
                          </TableCell>
                          <TableCell align="right">
                            {formatDate(item.created)}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Container>
    </>
  );
}

import React from "react";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SelectableCompany from "./SelectableCompany";
import { useSelector, useDispatch } from "react-redux";
import { setCompany } from "../../Redux/actions/companyActions";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {},
    grid: {
      height: "100vh"
    },
    paper: {
      padding: 16,
      maxWidth: 350,
      dsiplay: 'inline-block',
      margin: "0 auto",
      //width: "100%"
      //width: 350,
      //maxWidth: "calc(100% - 32px)"
    },
    title: {
      display: "block",
      textAlign: "center"
    },
    companies: {
      listStyle: "none",
      padding: 0,
      margin: "16px 0 0",
      maxHeight: "60vh",
      overflowY: "auto"
    },
    body: {
      textAlign: "center"
    }
  })
);

const SelectCompany = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const companies = useSelector(state => state.companies.companies);
  return (
    <Container>
      <Grid
        direction="row"
        container
        alignItems="center"
        justify="center"
        className={classes.grid}
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h1" className={classes.title}>
              Välj organisation
            </Typography>
            <Typography className={classes.body} gutterBottom>
              {/* Excepteur adipisicing occaecat eiusmod pariatur non esse consequat voluptate qui. */}
            </Typography>
            <ul className={classes.companies}>
              {companies.sort(function(a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}).map(({ id, name }) => {
                return <SelectableCompany key={id} name={name} id={id} />;
              })}
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
export default SelectCompany;
